import certificate1 from "../../assets/images/certificatesimages/certificate.png";
import certificate2 from "../../assets/images/certificatesimages/certificate2.png";
import certificate3 from "../../assets/images/certificatesimages/certificate3.png";
import share from "../../assets/svg/share.svg";
import phone from "../../assets/svg/phone/phone.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import star from "../../assets/svg/star.svg";
import days from "../../assets/svg/days.svg";
import startpoint from "../../assets/svg/startpoint.svg";
import file from "../../assets/svg/file.svg";
import circle from '../../assets/svg/circle.svg';


const CertificateCarasol = () => {
  return (
   <div className=" bg-bannerbg relative" >
     <section className=" 2xl:max-w-[1500px] md:w-[70%] mx-auto">
      <h1 className=" title">Free Course with certificates</h1>

      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper mx-auto py-10  !bg-transparent "
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
            <div className=" relative h-[150px] ">
              <img
                src={certificate1}
                alt=""
                className=" h-full w-full !object-cover rounded-md "
              />
              <a
                href=""
                className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start p-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
                  Software Development
                </h4>
                <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="text-base font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between w-full  ">
                  <button className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
            <div className=" relative h-[150px] ">
              <img
                src={certificate2}
                alt=""
                className=" h-full w-full !object-cover rounded-md "
              />
              <a
                href=""
                className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start p-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
                  Software Development
                </h4>
                <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="text-base font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between w-full  ">
                  <button className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
            <div className=" relative h-[150px] ">
              <img
                src={certificate3}
                alt=""
                className=" h-full w-full !object-cover rounded-md "
              />
              <a
                href=""
                className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start p-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
                  Software Development
                </h4>
                <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="text-base font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between w-full  ">
                  <button className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
            <div className=" relative h-[150px] ">
              <img
                src={certificate3}
                alt=""
                className=" h-full w-full !object-cover rounded-md "
              />
              <a
                href=""
                className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start p-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
                  Software Development
                </h4>
                <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="text-base font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between w-full  ">
                  <button className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
            <div className=" relative h-[150px] ">
              <img
                src={certificate2}
                alt=""
                className=" h-full w-full !object-cover rounded-md "
              />
              <a
                href=""
                className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start p-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
                  Software Development
                </h4>
                <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium text-xs text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="text-base font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between w-full  ">
                  <button className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
    <img
        src={circle}
        alt=""
        className=" absolute top-[30%] -left-10 max-md:hidden"
      />
   </div>
  );
};

export default CertificateCarasol;
