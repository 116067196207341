import React from 'react';
import ContactForm from '../../components/Contact/ContactForm';

const ContactPage = () => {
  return (
    <div className="">
      <ContactForm />
    </div>
  );
};

export default ContactPage;
