import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const DatePickerComp = () => {
  const [value, onChange] = useState(new Date());
  return (
    <div>
      <div className=" text-start mt-10 flex border-secondary border p-2 rounded-lg">
      
        <Calendar
          onChange={onChange}
          value={value}
          className=" !border-none !mx-auto"
        />
      </div>
    </div>
  );
};

export default DatePickerComp;
