import React, { useEffect, useState } from 'react';
import { Pagination, Navigation, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import ring from '../../assets/svg/circle.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';
import { GetMentors } from '../../services/Home.service';

const HerosCarasol = () => {
  const [mentors,setMentors]=useState([]);


useEffect(()=>{
  const getMentors=async ()=>{
    try{
      const mentorsData=await GetMentors();
      setMentors(mentorsData);
    }catch(err){
      console.log(err)
    }
  }

  getMentors();
},[]);

console.log(mentors);
  return (
    <div className=" relative bg-bannerbg">
      <section className=" 2xl:max-w-[1500px] w-[80%] mx-auto ">
        <h6 className=" 2xl:text-3xl max-2xl:text-lg text-primary font-medium">
          Mentors
        </h6>
        <h5 className="title mt-2">Meet The Heros</h5>
        <p className=" sub-title">
          On MAANG careers, instructors from all over the world <br /> who work
          at MAANG companies
        </p>
        <div className=" mt-10 py-5">
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            modules={[Navigation, Pagination, Mousewheel]}
            className="mySwiper mx-auto   "
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              850: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {mentors?.map((mentor,idx)=>{
              return   <SwiperSlide key={mentor?.id}>
              <div className=" mx-auto custom-shadow border max-w-[250px] border-primary p-4  flex flex-col justify-center items-center">
                <img
                  src={mentor?.photo}
                  alt=""
                  className="  h-20 w-20 rounded-full object-cover"
                />
                <h6 className=" 2xl:text-xl text-lg font-medium text-cblack my-1">
                  {mentor?.name}
                </h6>
                <h6 className=" 2xl:text-base text-sm text-secondary font-medium my-2">
                  {mentor?.maintext} <br /> {mentor?.subtext}
                </h6>
                <p className=" 2xl:text-base text-sm text-secondary font-medium">
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and...
                </p>
              </div>
            </SwiperSlide>
            })}
          </Swiper>
        </div>
      </section>
      <img
        src={ring}
        alt=""
        className=" absolute top-16 left-5 max-md:hidden"
      />
      <img
        src={dottcircle}
        alt=""
        className=" absolute top-5 right-24  max-md:hidden"
      />
        <img
        src={ring}
        alt=""
        className=" absolute top-[25%] -right-10 max-md:hidden"
      />
    </div>
  );
};

export default HerosCarasol;
