import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const TabsComp = () => {
  return (
    <div className=" mt-5">
      <Tabs>
        <TabList>
          <Tab>
            <div className=" custom-button bg-primary text-white ">All</div>
          </Tab>
          <Tab>
            <div className=" custom-button  "> General</div>
          </Tab>
          <Tab>
            <div className=" custom-button  "> Technical</div>
          </Tab>
          <Tab>
            <div className=" custom-button   "> Others</div>
          </Tab>
        </TabList>

        <TabPanel></TabPanel>
        <TabPanel></TabPanel>
      </Tabs>
    </div>
  );
};

export default TabsComp;
