import certificate1 from '../../assets/images/certificatesimages/certificate.png';
import certificate2 from '../../assets/images/certificatesimages/certificate2.png';
import certificate3 from '../../assets/images/certificatesimages/certificate3.png';
import share from '../../assets/svg/share.svg';
import phone from '../../assets/svg/phone/phone.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import star from '../../assets/svg/star.svg';
import days from '../../assets/svg/days.svg';
import startpoint from '../../assets/svg/startpoint.svg';
import file from '../../assets/svg/file.svg';
import righarrow from '../../assets/svg/Rightsidearrow/whiteRightarrow.svg';

const ProgramCarasol = () => {
  return (
    <section className=" sub-section">
      <div className=" flex justify-between items-center max-md:flex-col ">
        {' '}
        <h1 className=" title">Free Course with certificates</h1>
        <button className=" custom-button flex justify-between gap-2 items-center  bg-primary border-primary text-white">
          Explore free Course{' '}
          <span>
            {' '}
            <img src={righarrow} alt="" />
          </span>
        </button>
      </div>

      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper mx-auto  "
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2 2xl:max-w-cardmw max-2xl:max-w-mw350 mt-10 ">
            <div className=" relative">
              <img src={certificate1} alt="" className=" h-full w-full " />
              <a
                href=""
                className=" h-10 w-10 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start py-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-base font-medium  text-secondary">
                  Software Development
                </h4>
                <button className="custom-button  !py-1 text-white gap-1 bg-primary flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-2xl:text-xl max-2xl:text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="2xl:text-3xl max-2xl:text-lg font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between  pr-4">
                  <button className="  shrink-0 rounded-full py-1  2xl:!text-xl max-2xl:!text-xs !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className=" shrink-0 rounded-full py-1   2xl:!text-xl !font-medium max-2xl:!text-xs border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2 2xl:max-w-cardmw max-2xl:max-w-mw350 mt-10 ">
            <div className=" relative">
              <img src={certificate3} alt="" className=" h-full w-full " />
              <a
                href=""
                className=" h-10 w-10 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start py-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-base font-medium  text-secondary">
                  Software Development
                </h4>
                <button className="custom-button  !py-1 text-white gap-1 bg-primary flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-2xl:text-xl max-2xl:text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="2xl:text-3xl max-2xl:text-lg font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between  pr-4">
                  <button className=" rounded-full py-1 shrink-0   2xl:!text-xl max-2xl:!text-xs !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="rounded-full py-1 shrink-0    2xl:!text-xl !font-medium max-2xl:!text-xs border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2 2xl:max-w-cardmw max-2xl:max-w-mw350 mt-10 ">
            <div className=" relative">
              <img src={certificate2} alt="" className=" h-full w-full " />
              <a
                href=""
                className=" h-10 w-10 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start py-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-base font-medium  text-secondary">
                  Software Development
                </h4>
                <button className="custom-button  !py-1 text-white gap-1 bg-primary flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-2xl:text-xl max-2xl:text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="2xl:text-3xl max-2xl:text-lg font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between  pr-4">
                  <button className=" rounded-full py-1 shrink-0   2xl:!text-xl max-2xl:!text-xs !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="rounded-full py-1 shrink-0    2xl:!text-xl !font-medium max-2xl:!text-xs border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2 2xl:max-w-cardmw max-2xl:max-w-mw350 mt-10 ">
            <div className=" relative">
              <img src={certificate2} alt="" className=" h-full w-full " />
              <a
                href=""
                className=" h-10 w-10 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start py-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-base font-medium  text-secondary">
                  Software Development
                </h4>
                <button className="custom-button  !py-1 text-white gap-1 bg-primary flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-2xl:text-xl max-2xl:text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="2xl:text-3xl max-2xl:text-lg font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between  pr-4">
                  <button className=" rounded-full py-1 shrink-0   2xl:!text-xl max-2xl:!text-xs !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="rounded-full py-1 shrink-0    2xl:!text-xl !font-medium max-2xl:!text-xs border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className=" custom-shadow mx-auto p-2 2xl:max-w-cardmw max-2xl:max-w-mw350 mt-10 ">
            <div className=" relative">
              <img src={certificate2} alt="" className=" h-full w-full " />
              <a
                href=""
                className=" h-10 w-10 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
              >
                <img src={share} alt="" />
              </a>
            </div>
            <div className=" text-start py-2 ">
              <div className=" flex justify-between items-center">
                <h4 className=" text-base font-medium  text-secondary">
                  Software Development
                </h4>
                <button className="custom-button  !py-1 text-white gap-1 bg-primary flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <h3 className=" text-2xl:text-xl max-2xl:text-lg mt-2 font-semibold text-cblack">
                Data Structures and Algorithms (C++)
              </h3>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={days} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Lorem ispum
                  </span>
                </li>
              </ul>
              <ul className="flex justify-between items-center my-2 pr-4">
                <li className=" flex gap-2  ">
                  <img src={startpoint} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    3 Month
                  </span>
                </li>
                <li className=" flex gap-2 ">
                  <img src={file} alt="" />
                  <span className=" font-medium 2xl:text-base max-2xl:text-sm text-secondary">
                    Hybrid Classes
                  </span>
                </li>
              </ul>
              <div className=" mt-3">
                <h4 className="2xl:text-3xl max-2xl:text-lg font-bold">Free</h4>
                <div className=" mt-2 flex  justify-between  pr-4">
                  <button className=" rounded-full py-1 shrink-0   2xl:!text-xl max-2xl:!text-xs !font-medium border-primary text-primary !border !px-5">
                    Learn more
                  </button>
                  <button className="rounded-full py-1 shrink-0    2xl:!text-xl !font-medium max-2xl:!text-xs border-primary text-white bg-primary !border !px-5">
                    Enroll Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default ProgramCarasol;
