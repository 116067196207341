import React from 'react';
import img1 from '../../assets/images/works/workimgbaner.png';
import phone from '../../assets/svg/phone/bluephone.svg';
const WorkImgBanner = () => {
  return (
    <div className="w-[80%] mx-auto">
      <div className=" rounded-lg relative  bg-primary p-4   flex max-h-44 h-full  justify-start gap-4 items-center">
        <div className="">
          {' '}
          <img
            src={img1}
            className=" max-w-72 max-h-64 h-full w-full object-cover relative -top-2     max-md:hidden"
            alt=""
          />
        </div>
        <div className=" mx-auto ">
          <div className=" 2xl:text-3xl max-2xl:text-3xl flex flex-col justify-center items-center   font-medium text-white">
            Still Confused which programs to choose ?
          </div>
          <button className=" mt-5 mx-auto custom-button bg-white text-primary flex gap-2 w-[10/12]">
            <img src={phone} alt="" />
            <span>Request a free Call</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkImgBanner;
