import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import { Link } from "react-router-dom";
import arrowwhite from "../../assets/svg/whiteArrow.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Signup } from "../../services/Auth.service";

const SignupComp = () => {
  const [error, setError] = useState();
  const SingupHandler = async (values, { setSubmitting }) => {
    try {
      const payload = {
        user: {
          username: values.email,
          email: values.email,
          first_name: values.username.split(" ")[0],
          last_name: values.username.split(" ").slice(1).join(" "),
          password: values.password,
        },
        phone_num: values.phone,
      };

      await Signup(payload);
      setSubmitting(false);
      window.location.replace("/login");
    } catch (error) {
      if (error.response.status === 400)
        setError("Email address already in use");
      else setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number is not valid")
        .required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
    }),
    onSubmit: SingupHandler,
  });
  return (
    <>
      <div className="flex-1">
        <form className="p-4 w-full " onSubmit={formik.handleSubmit}>
          <div className=" flex justify-between items-center">
            <h4 className=" text-cblack text-2xl font-semibold ">Sign Up</h4>
            <Link
              to="/login"
              className=" custom-button  border-cblack max-sm:hidden  border font-bold"
            >
              Login
            </Link>
          </div>
          <div className=" mt-4 md:w-[80%]">
            {" "}
            <div className=" text-start mt-2">
              <label className=" text-base  text-cblack font-semibold">
                Full Name*
              </label>{" "}
              <input
                name="username"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                type="text"
                placeholder="Your Full name"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" text-start mt-2">
              <label className=" text-base  text-cblack font-semibold">
                Email*
              </label>{" "}
              <input
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                type="text"
                placeholder="Your Full Email"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" text-start mt-2">
              <label className=" text-base  text-cblack font-semibold">
                Phone
              </label>{" "}
              <input
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                type="number"
                placeholder="Your Full Number"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" text-start mt-2">
              <label className=" text-base  text-cblack font-semibold">
                Password*
              </label>{" "}
              <input
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                type="password"
                placeholder="Your Full password"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" text-start mt-2">
              <label className=" text-base  text-cblack font-semibold">
                Confirm Password*
              </label>{" "}
              <input
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                type="password"
                placeholder="Your Full Confirm Password*"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" mt-4">
              <button className=" bg-black text-white custom-button w-full  mx-auto">
                Create An Account
              </button>
            </div>
            <div className=" mt-4  text-sm  text-secondary font-semibold">
              Already have an account ?{" "}
              <Link to="/login" className=" text-cblack">
                Log in
              </Link>{" "}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignupComp;
