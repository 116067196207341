import React, { useEffect } from 'react';
import avtar from '../../assets/images/blog/blog.png';
import eye from '../../assets/svg/eye.svg';
import message from '../../assets/svg/msg.svg';
import liked from '../../assets/svg/likethumb.svg';
import BlogsCards from '../../components/blog/BlogsCards';
import loadmore from '../../assets/svg/loadmore.svg';
import blog2 from '../../assets/images/readblog2.png';
import card1 from '../../assets/images/blog/blog1.png';
import card2 from '../../assets/images/blog/blog2.png';
import card3 from '../../assets/images/blog/blog3.png';
import card4 from '../../assets/images/blog/blog4.png';
import rightarrow from '../../assets/rightarrow.svg';
import { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { useParams } from 'react-router-dom';
import { GetBlog, GetBlogs } from '../../services/Blog.service';

const ReadBlogPage = () => {
  const { id } = useParams();
  const [blog,setBlog]=useState([]);
  const [blogs,setBlogs]=useState([]);

  useEffect(()=>{
    const getBlog=async ()=>{
      try{
        const blogData=await GetBlog(id);
        setBlog(blogData);
      }catch(err){
        console.log(err)
      }
    }

    getBlog();
  },[id])

useEffect(()=>{
  const getBlogs=async ()=>{
    try{
      const blogsData=await GetBlogs();
      setBlogs(blogsData);
    }catch(err){
      console.log(err)
    }
  }

  getBlogs();
},[])



const getDate = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const formattedDate = `${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;

  return formattedDate;
};

console.log("Single blog",blog);
console.log("All blogs",blogs);

const sortedTopics = blog?.topics?.sort((a, b) => a.id - b.id);
const sortedBlogs = blogs
  .filter(b => b.id !== parseInt(id)) 
  .sort((a, b) => b.views - a.views);

  return (
    <div className="">
      <section className=" sub-section text-start ">
        <div className=" flex justify-between sm:items-center max-sm:flex-col  gap-3">
          <div className="font-sans flex-1">
            <h4 className="text-sm text-secondary font-sans ">
              General • {blog?.read_time} min
            </h4>
            <h1 className=" title !leading-[1]">
              {blog?.title}
            </h1>
          </div>
          <div className=" flex flex-1 sm:justify-end  gap-2 items-center font-sans  max-md:mt-5">
            <img
              src={avtar}
              alt=""
              className="2xl:h-20 2xl:w-20 h-12  w-12 rounded-full "
            />
            <div className=" ">
              <h4 className=" text-nowrap 2xl:text-2xl font-medium text-secondary text-xs max-md:text-sm">
                Write By
              </h4>
              <h6 className="text-nowrap 2xl:text-sm font-medium text-cblack text-sm max-md:text-xs">
                Jane Cooper
              </h6>
            </div>
          </div>
        </div>
        <div className=" mt-10">
          <div className="  max-h-[500px] overflow-hidden ">
            <img src={blog?.photo} alt="" className=" w-full  h-full object-cover" />
          </div>
        </div>
        <div className=" mt-10 grid grid-cols-12 max-md:grid-cols-10 ">
          <ul className=" md:col-span-2 col-span-9 font-sans max-md:grid grid-cols-3  justify-between">
            <li className=" mb-3">
              {' '}
              <p className="  max-2xl:text-sm 2xl:text-sm text-secondary font-normal">
                Date
              </p>
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base">
                {getDate(blog?.date)}
              </h4>
            </li>
            <li className=" my-3">
              {' '}
              <p className="  max-2xl:text-sm 2xl:text-sm text-secondary font-normal">
                Facebook
              </p>
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base">
                Jane Cooper
              </h4>
            </li>
            <li className=" my-3">
              {' '}
              <p className="  max-2xl:text-sm 2xl:text-sm text-secondary font-normal">
                Instagram
              </p>
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base">
                Jane Cooper
              </h4>
            </li>
            <li className=" my-3">
              {' '}
              <p className="  max-2xl:text-sm 2xl:text-sm text-secondary font-normal">
                Linkedin
              </p>
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base">
                Jane Cooper
              </h4>
            </li>
            <li className=" my-3">
              {' '}
              <p className="  max-2xl:text-sm 2xl:text-sm text-secondary font-normal">
                Youtube
              </p>
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base">
                Jane Cooper
              </h4>
            </li>
          </ul>
          <div className=" md:col-span-10 col-span-9  font-sans">
            <p className=" 2xl:text-2xl font-semibold max-2xl:text-lg">
             {blog?.title}
            </p>
            <h4 className=" 2xl:text-xl font-semibold max-2xl:text-lg mt-5">
              Introduction
            </h4>
            <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
            {blog?.text}
            </p>
            <div className=" mt-5">
              <div className=" max-h-[350px] overflow-hidden w-full object-cover">
                <img src={blog?.photo} alt="" className=" w-full object-cover " />
              </div>
              <ol className=" mt-5 list-none 2xl:text-2xl font-semibold max-2xl:text-lg max-md:ml-5">
                {sortedTopics?.map((topic,idx)=>{
                  return    <li key={topic?.id} className="  my-2">
                  <h4 className="">{topic?.title}</h4>
                  <p className="  max-2xl:text-sm 2xl:text-base text-secondary font-normal">
                   {topic?.text}
                  </p>
                </li>
                })}
              </ol>
            </div>
            <ul className=" text-xs text-primary flex gap-4 justify-start items-center">
              <li className=" flex gap-1 items-center">
                <img src={eye} alt="" /> <span className=" text-xs">{blog?.views} </span>
              </li>
              <li className=" flex gap-1 items-center ">
                <img src={message} alt="" />{' '}
                <span className=" text-xs">{blog?.comments?.length || 0} </span>
              </li>
              <li className=" flex gap-1 items-center ">
                <img src={liked} alt="" />{' '}
                <span className=" text-xs">{blog?.likes || 0} </span>
              </li>
            </ul>
            <div className=" mt-5 w-4/6 max-md:w-full object-cover">
              <h4 className=" 2xl:text-2xl font-semibold max-2xl:text-base mt-5">
                Comment
              </h4>
              <textarea
                cols=""
                rows="5"
                placeholder=" type here"
                className=" w-full object-cover ml-5 p-2 outline-none border-cborder border-2 custom-shadow text-sm mt-5 font-medium font-sans"
              ></textarea>
              <button className=" custom-button bg-primary text-white ml-auto mt-5">
                Submit
              </button>
              <div className=" flex  gap-2 items-start font-sans mt-10 ">
                <img
                  src={avtar}
                  alt=""
                  className="2xl:h-16 2xl:w-16 h-10 w-10 rounded-full relative -top-2 object-cover"
                />
                <div>
                  <div className=" flex justify-between items-center">
                    <h6 className=" 2xl:text-sm font-medium text-cblack max-2xl:text-base">
                      Somi Sing
                    </h6>
                    <h6 className=" text-xs font-medium text-secondary">
                      12 Mar, 2022
                    </h6>
                  </div>

                  <p className=" max-2xl:text-sm 2xl:text-sm text-secondary font-normal mt-2">
                    Sollicitudin nibh commodo nulla facilisi. Velit scelerisque
                    in Vitae tortor condiment lacinia quis vel. Bibendum
                    tristique et egestas
                  </p>
                  <div className="text-primary text-sm flex mt-2 gap-1 justify-start items-center">
                    <img src={liked} alt="" />{' '}
                    <span className=" text-xs">170 </span>
                  </div>
                </div>
              </div>
              <div className=" flex  gap-2 items-start font-sans mt-10 ">
                <img
                  src={avtar}
                  alt=""
                  className="2xl:h-16 2xl:w-16 h-10 w-10 rounded-full relative -top-2 object-cover"
                />
                <div>
                  <div className=" flex justify-between items-center">
                    <h6 className=" 2xl:text-sm font-medium text-cblack max-2xl:text-base">
                      Somi Sing
                    </h6>
                    <h6 className=" text-xs font-medium text-secondary">
                      12 Mar, 2022
                    </h6>
                  </div>

                  <p className=" max-2xl:text-sm 2xl:text-sm text-secondary font-normal mt-2">
                    Sollicitudin nibh commodo nulla facilisi. Velit scelerisque
                    in Vitae tortor condiment lacinia quis vel. Bibendum
                    tristique et egestas
                  </p>
                  <div className="text-primary text-sm flex mt-2 gap-1 justify-start items-center">
                    <img src={liked} alt="" />{' '}
                    <span className=" text-xs">170 </span>
                  </div>
                </div>
              </div>
              <div className=" flex  gap-2 items-start font-sans mt-10 ">
                <img
                  src={avtar}
                  alt=""
                  className="2xl:h-16 2xl:w-16 h-10 w-10 rounded-full relative -top-2 object-cover"
                />
                <div>
                  <div className=" flex justify-between items-center">
                    <h6 className=" 2xl:text-sm font-medium text-cblack max-2xl:text-base">
                      Somi Sing
                    </h6>
                    <h6 className=" text-xs font-medium text-secondary">
                      12 Mar, 2022
                    </h6>
                  </div>

                  <p className=" max-2xl:text-sm 2xl:text-sm text-secondary font-normal mt-2">
                    Sollicitudin nibh commodo nulla facilisi. Velit scelerisque
                    in Vitae tortor condiment lacinia quis vel. Bibendum
                    tristique et egestas
                  </p>
                  <div className="text-primary text-sm flex mt-2 gap-1 justify-start items-center">
                    <img src={liked} alt="" />{' '}
                    <span className=" text-xs">170 </span>
                  </div>
                </div>
              </div>
              <button className=" mt-10 flex justify-center items-center gap-1 border-primary custom-button text-secondary mx-auto">
                <span>
                  <img src={loadmore} alt="" />
                </span>
                Load More
              </button>
            </div>
          </div>
        </div>
        <div className=" mt-20">
          <h4 className=" title !md:text-start text-start">Must read blogs</h4>
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper mx-auto py-2    "
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              650: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              980: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            {sortedBlogs?.map((blog)=>{
              return      <SwiperSlide key={blog?.id} className=" !col-span-1">
              <div className="custom-shadow mx-auto  2xl:max-w-cardmw max-w-mw350 mt-10 font-sans overflow-hidden   ">
                <img
                  src={blog?.photo}
                  alt=""
                  className="  max-h-[160px] h-full w-full object-cover rounded-xl"
                />

                <div className="custom-shadow max-w-96  p-3  !bg-white  mx-auto relative z-50">
                  <div className=" text-start flex  flex-col items-start">
                    {' '}
                    <h3 className="2xl:text-base max-md:text-xs md:text-xs text-secondary font-normal">
                      General {getDate(blog?.date)}
                    </h3>
                    <h4 className=" 2xl:text-2xl md:text-sm lg:text-sm max-md:text-sm text-cblack font-semibold ">
                      {blog?.title}
                    </h4>
                    <p class=" 2xl:text-base max-md:text-xs md:text-xs text-secondary font-normal">
                  {blog?.text?.slice(0,200)}
                    </p>
                    <div className=" flex justify-between items-center w-full  mt-4">
                      <ul className=" text-[10px] text-primary flex gap-2 justify-start items-center">
                        <li className=" flex gap-1 items-center">
                          <img src={eye} alt="" />{' '}
                          <span className=" text-xs">{blog?.views}</span>
                        </li>
                        <li className=" flex gap-1 items-center ">
                          <img src={message} alt="" />{' '}
                          <span className=" text-xs">{blog?.comments?.length || 0}</span>
                        </li>
                        <li className=" flex gap-1 items-center ">
                          <img src={liked} alt="" />{' '}
                          <span className=" text-xs">{blog?.likes || 0}</span>
                        </li>
                      </ul>
                      <a
                        href=""
                        className=" bg-primary px-2 py-1 text-white rounded-full text-[10px] font-medium flex items-center"
                      >
                        <span>Read More</span>
                        <img
                          src={rightarrow}
                          alt=""
                          className=" max-h-5 max-w-4"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            })}
          </Swiper>
        </div>
      </section>
    </div>
  );
};

export default ReadBlogPage;
