import React from 'react';
import logo from '../../assets/svg/footerlogo.svg';
import facebook from '../../assets/svg/socialmedia/facebook.svg';
import twitter from '../../assets/svg/socialmedia/twitter.svg';

import insta from '../../assets/svg/socialmedia/instagram.svg';
import linkdien from '../../assets/svg/socialmedia/linkdien.svg';
import youtube from '../../assets/svg/socialmedia/youtube.svg';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {

  const location = useLocation()

  const removeFooter = location.pathname === "/login" || location.pathname === "/signup" ||  location.pathname === "/resetpassword" 
|| location.pathname === "/createpassword" || location.pathname === "/resetsuccess" || location.pathname === "/otpsuccess" || location.pathname === "/otpvarify"

  if(removeFooter){
    return null
  }
  return (
    <>
      <div className=" bg-cnavy pt-28 pb-8">
        <div className=" sub-section max-sm:text-xs max-md:grid-cols-2 grid grid-cols-5 text-start text-cgray font-normal border-b border-secondary pb-5">
          <ul className=" max-md:col-span-2 !my-0">
            <img src={logo} alt="" className=" text-xl" />
            <li className="my-4">
              Explore the Limits MAANG Careers (OPC) Private Limited{' '}
            </li>

            <li className=" flex gap-2 my-4 ">
              <div
                href=""
                className=" border border-primary py-2 px-2 rounded-full"
              >
                {' '}
                <a href="">
                  <img src={facebook} alt="" />
                </a>
              </div>
              <div
                href=""
                className=" border border-primary py-2 px-2     rounded-full"
              >
                {' '}
                <a href="">
                  <img src={twitter} alt="" />
                </a>
              </div>

              <div
                href=""
                className=" border border-primary py-2 px-2 rounded-full"
              >
                {' '}
                <a href="">
                  <img src={insta} alt="" />
                </a>
              </div>
              <div
                href=""
                className=" border border-primary py-2 px-2 rounded-full"
              >
                {' '}
                <a href="">
                  <img src={linkdien} alt="" />
                </a>
              </div>
              <div
                href=""
                className=" border border-primary py-2 px-2 rounded-full"
              >
                {' '}
                <a href="">
                  <img src={youtube} alt="" />
                </a>
              </div>
            </li>
          </ul>
          <ul className=" md:mx-auto ">
            <Link to="/" className=" text-xl text-white font-medium">
              Home
            </Link>
            <li className="my-4">Programs</li>
            <li className="my-4">Mentors</li>
            <li className="my-4">Mentors</li>
          </ul>
          <ul className=" md:mx-auto ">
            <li href="" className=" text-xl text-white font-medium">
              Company
            </li>
            <li className="my-4">
              <Link to="/about">About Us</Link>
            </li>
            <li className="my-4">
              <Link to="/works"> How it works</Link>
            </li>
            <li className="my-4">Media</li>
            <li className="my-4">Testimonial</li>
          </ul>
          <ul className=" md:mx-auto ">
            <a href="" className=" text-xl text-white font-medium">
              Legal
            </a>
            <li className="my-4">Privacy Policy</li>
            <li className="my-4">
              <Link to="/termscondition">Terms and Coditions</Link>
            </li>
            <li className="my-4">Return and Refund Policy</li>
            <li className="my-4">Shipping & Delivery Policy</li>
          </ul>
          <ul className=" md:ml-auto ">
            <a href="" className=" text-xl text-white font-medium">
              Contact Us
            </a>
            <li className="my-4">+1 4587 5214 8548</li>
            <li className="my-4">+1 2458 7546 8547</li>
            <li className="my-4">example@gmail.com</li>
          </ul>
        </div>
        <div className="  text-lg text-white font-medium font-sans p-3">
          <h1 className=" py-auto mt-5">
            {' '}
            © 2023 MAANG Careers (OPC) Private Limited. All rights reserved.
          </h1>
        </div>
      </div>
    </>
  );
};

export default Footer;
