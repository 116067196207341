import React from 'react';
import google from '../../assets/svg/companyclr/googlecl.svg';
import amazon from '../../assets/svg/companyclr/amazonclr.svg';
import microsoft from '../../assets/svg/companyclr/microsoft.svg';
import iphone from '../../assets/svg/companyclr/iphone.svg';
import netflix from '../../assets/svg/companyclr/netflix.svg';
import capsule from '../../assets/svg/reconisged/capsule.svg';
import sperul from '../../assets/svg/reconisged/spheral.svg';
import luminous from '../../assets/svg/reconisged/luminos.svg';
import focal from '../../assets/svg/reconisged/focal.svg';
import dottcircle from "../../assets/svg/dotcircle.svg"
import ring from "../../assets/svg/circle.svg" 

const CompanyBanner = () => {
  return (
    <div className="bg-bannerbg relative">
      <section className=" sub-section ">
        <h5 className="title !leading-[1.3]">
          Land your dream job at soft <br /> MAANG Companies
        </h5>
        <div className=" grid grid-cols-5 mt-10   mx-auto  text-center justify-start items-center max-sm:grid-cols-3 gap-6">
          <img src={google} alt="" className=" my-1" />
          <img src={amazon} alt="" className=" my-1" />
          <img src={microsoft} alt="" className=" my-1" />
          <img src={iphone} alt="" className=" my-1" />
          <img src={netflix} alt="" className=" my-1" />
          <img src={capsule} alt="" className=" my-1" />
          <img src={sperul} alt="" className=" my-1" />
          <img src={luminous} alt="" className=" my-1 " />
          <img src={sperul} alt="" className=" my-1" />
          <img src={focal} alt="" className=" my-1 max-sm:hidden" />
        </div>
      </section>
      <img src={ring} alt=''  className=' max-md:hidden absolute -left-12  top-16'/>
      <img src={ring} alt=''  className=' max-md:hidden absolute -right-10  -top-8'/>

      <img src={dottcircle} alt=''  className=' max-md:hidden absolute right-10 top-[15%]'/>

    </div>
  );
};

export default CompanyBanner;
