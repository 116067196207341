import React, { useState } from 'react';
import payment from '../../assets/svg/faq/payemet.svg';
import corse from '../../assets/svg/faq/course.svg';
import Privacy from '../../assets/svg/faq/primary.svg';
import people from '../../assets/svg/faq/people.svg';
import circle from '../../assets/svg/circle.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';
import TermsCondComp from '../../components/termsconditon/TermsCondComp';
import PrivacyPolicy from '../../components/termsconditon/PrivacyPolicy';
import ReturnRefund from '../../components/termsconditon/ReturnRefund';
import ShippingDeleviry from '../../components/termsconditon/ShippingDeleviry';

const TermsConditions = () => {
  const [activeComponent, setActiveComponent] = useState('terms'); // State to track active component

  return (
    <div className="relative pt-20">
      <div className="sub-section">
        <div className="2xl:text-2xl font-sans text-start rounded-md font-semibold max-2xl:text-xl text-cblack">
          <span>Terms & Conditions</span>
        </div>
        <section className="py-5 grid grid-cols-12 max-md:grid-cols-8 font-sans gap-2 !mt-0">
          <div className="col-span-3 max-md:col-span-8 px-4 custom-shadow">
            <ul className="text-start font-sans ">
              <li
                className={`2xl:text-2xl flex gap-2 items-center p-2 rounded-md font-medium max-2xl:text-sm  my-3 ${
                  activeComponent === 'terms' ? 'text-white bg-primary' : 'text-secondary'
                }`}
                onClick={() => setActiveComponent('terms')}
              >
                <img src={payment} alt="" />
                <button className="">Terms & conditions</button>
              </li>
              <div className='md:hidden'>
              {activeComponent === 'terms' && <TermsCondComp />}
              </div>
              <li
                className={`2xl:text-2xl border-b pb-2 border-cborder flex gap-2 items-start p-2 rounded-md font-medium max-2xl:text-sm text-secondary my-3 hover:text-white hover:bg-primary ${
                  activeComponent === 'privacy' ? 'bg-primary text-white' : ''
                }`}
                onClick={() => setActiveComponent('privacy')}
              >
                <img src={Privacy} alt="" />
                <button className="">Privacy Policy</button>
              </li>
              <div className='md:hidden'>
              {activeComponent === 'privacy' && <PrivacyPolicy />}
              </div>

              <li
                className={`2xl:text-2xl border-b pb-2 border-cborder flex gap-2 items-start p-2 rounded-md font-medium max-2xl:text-sm text-secondary my-3 hover:text-white hover:bg-primary ${
                  activeComponent === 'return' ? 'bg-primary text-white' : ''
                }`}
                onClick={() => setActiveComponent('return')}
              >
                <img src={Privacy} alt="" />
                <button className="">Return & refund policy</button>
              </li>
              <div className='md:hidden'>
              {activeComponent === 'return' && <ReturnRefund/>}
              </div>
              <li
                className={`2xl:text-2xl border-b pb-2 border-cborder flex gap-2 items-start p-2 rounded-md font-medium max-2xl:text-sm text-secondary my-3 hover:text-white hover:bg-primary ${
                  activeComponent === 'shipping' ? 'bg-primary text-white' : ''
                }`}
                onClick={() => setActiveComponent('shipping')}
              >
                <img src={Privacy} alt="" />
                <button className=" text-start">Shipping & Delivery Policy</button>
              </li>
              <div className='md:hidden'>
              {activeComponent === 'shipping' && <ShippingDeleviry />}
              </div>
             
              {/* Similarly for other list items */}
            </ul>
          </div>
          <div className="col-span-9 font-sans px-4 text-start max-md:hidden max-md:col-span-8">
            {activeComponent === 'terms' && <TermsCondComp />}
            {activeComponent === 'privacy' && <PrivacyPolicy />}
            {activeComponent === 'return' && <ReturnRefund/>}
             {activeComponent === 'shipping' && <ShippingDeleviry />}
            {/* Render other components based on activeComponent */}
            <button className="custom-button mx-auto bg-primary text-white mt-5" onClick={() => setActiveComponent('')}>
              Back to Top
            </button>
          </div>
        </section>
      </div>
      {/* Additional code for images */}
    </div>
  );
};

export default TermsConditions;
