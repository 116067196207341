import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import arrowwhite from "../../assets/svg/whiteArrow.svg";
import { Link } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";
import { IsUserValid, Login } from "../../services/Auth.service";
import { useCookie } from "react-use";
import { useUserStore } from "../../store/store";
import { handleAxiosError } from "../utils/HandleAxiosError";

const LoginComp = () => {
  const [, updateLoggedIn] = useCookie("maang");
  const [error, setError] = useState(null);
  const updateUser = useUserStore((state) => state.updateUser);
  const LoginHandler = async (values, { setSubmitting, ...rest }) => {
    try {
      const payload = {
        username: values.email,
        password: values.password,
      };
      const data = await Login(payload);
      console.log(data);
      updateLoggedIn(data);
      updateUser(data);
      if (data.user) {
        let instructorCheckResponse = await IsUserValid();
        const role = instructorCheckResponse?.data?.role;
        if (role === "Instructor") {
          window.location.replace("/mentor");
          return;
        }

        if (role === "Student") {
          window.location.replace("/");
          return;
        }
        window.location.replace("/login");
      }
    } catch (err) {
      handleAxiosError(setError, err);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: LoginHandler,
  });

  console.log(error?.non_field_errors?.[0]);

  return (
    <>
      <div className=" flex justify-center items-center absolute top-5 right-0">
        <Link
          to="/signup"
          className=" custom-button ml-auto max-sm:hidden   border-cblack  border font-bold"
        >
          Sign Up
        </Link>
      </div>
      <form className="p-4  lg:w-[80%] " onSubmit={formik.handleSubmit}>
        <div className=" mt-4">
          <h4 className=" text-start text-cblack  text-2xl font-semibold ">
            Log In
          </h4>{" "}
          {error && (
            <p className=" text-base text-[#D83939] text-start  font-normal  mt-2">
              {error?.non_field_errors?.[0]}
            </p>
          )}
          <div className=" text-start mt-2">
            <label className=" text-base text-cblack font-semibold">
              Email*
            </label>{" "}
            <input
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              type="text"
              placeholder="Your Full Email"
              className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
            />
          </div>
          <div className=" text-start mt-2">
            <label className=" text-base text-cblack font-semibold">
              Password*
            </label>{" "}
            <input
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              type="password"
              placeholder="Your Full password"
              className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
            />
          </div>
        </div>
        <div className=" mt-4">
          <Link
            to="/resetpassword"
            className="  text-[#2DB2C4] text-sm font-medium  w-full  flex justify-end"
          >
            Reset your password.
          </Link>
        </div>
        <div className=" mt-4">
          <button
            type="submit"
            className=" bg-black text-white custom-button w-full  mx-auto"
          >
            Login
          </button>
        </div>
        <div className=" mt-4 text-sm  text-secondary font-semibold">
          Don’t have an account ?
          <Link to="/signup" className=" text-primary">
            Signup
          </Link>{" "}
        </div>
      </form>
    </>
  );
};

export default LoginComp;
