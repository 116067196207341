import React from 'react';
import circle from '../../assets/svg/circle.svg';
import lock from '../../assets/svg/lock.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';
const ProgramMain = () => {
  return (
    <div className=" bg-white relative mt-10">
      <section className=" mx-auto  md:w-[60%] 2xl:max-w-[950px]  md:px-10 px-2">
        <h1 className=" title">All Preparation Programs</h1>
        <p className=" sub-title">
          Unlock your coding potential to become a software developer
        </p>
        <h5 className=" text-primary font-semibold mt-2 text-base">
          Choose your programs
        </h5>
        <div className=" mt-4 flex mx-auto pb-2 items-center gap-3  md:gap-8    justify-center md:justify-center   overflow-x-scroll ">
          <button className="  text-[10px] text-nowrap py-1 px-2 rounded-full bg-primary border-primary text-white">
            Interview Preparation
          </button>
          <button className="  text-[10px] text-nowrap py-1 px-2 rounded-full font-medium border border-cborder  flex justify-start items-center   text-secondary">
            <span>
              <img src={lock} alt="" className=" mr-1" />
            </span>
            Web Development
          </button>
          <button className="  text-[10px] text-nowrap flex justify-start items-center py-1 px-2 rounded-full font-medium  border border-cborder    text-secondary">
            <span>
              <img src={lock} alt="" className=" mr-1" />
            </span>
            Data Science
          </button>
          <button className="  text-[10px] text-nowrap py-1 px-2 rounded-full border font-medium border-cborder   text-secondary">
            Internship
          </button>
        </div>
        <div className=" mt-6 flex gap-6 justify-center max-md:items-center items-start !w-[50%]   mx-auto    max-md:flex-col">
          {/* left side */}
          <div className="  custom-shadow py-4 px-6 text-start max-w-[430px] ">
            <div className=" flex justify-between items-center border-b border-secondary pb-2">
              <h3 className=" max-2xl:text-lg 2xl:text-3xl font-semibold">
                Fast tract{' '}
              </h3>
              <h4 className=" 2xl:text-xl max-2xl:text-sm font-semibold">
                3 Month <br />{' '}
                <span className=" 2xl:!text-sm max-2xl:!text-xs text-secondary text-center">
                  Duration
                </span>
              </h4>
            </div>
            <div className=" 2xl:text-lg max-2xl:text-sm  font-medium my-2">
              Eligibility: Bachelors 1 , 2, 3, Jrs Cording Lore ipsum dolor sit
              amet
            </div>
            <div className="  text-[10px]  font-medium text-secondary my-2 flex gap-1 mt-3 overflow-x-scroll">
              <button className=" px-2 py-1  bg-clightbg rounded-full text-nowrap">
                Languages
              </button>
              <button className=" px-2 py-1  bg-clightbg rounded-full text-nowrap">
                Data structures
              </button>
              <button className=" px-2 py-1  bg-clightbg rounded-full text-nowrap">
                CSE Subject
              </button>
              <button className=" px-2 py-1  bg-clightbg rounded-full text-nowrap">
                System Dessign
              </button>
            </div>
            <ul className="my-2 text-cblack">
              <h4 className=" text-base font-normal text-cblack">
                Programs Including
              </h4>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  checked
                  type="checkbox"
                  className=" h-5 w-5 !rounded-full  !text-white checkbox-green  "
                />
                <span className="ml-2">200+ coding questions</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green"
                />
                <span className="ml-2">1 Algorithmic project</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green  "
                  id="myCheckbox"
                />
                <span className="ml-2">Quiz and Mock tests</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green"
                />
                <span className="ml-2">Instant doubt resolution</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green"
                />
                <span className="ml-2">Resume building</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green"
                />
                <span className="ml-2">Internship referral (optional)</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-green"
                />
                <span className="ml-2">Course certificate</span>
              </li>
            </ul>
            <div className="">
              {' '}
              <button className=" mx-auto text-white custom-button border-primary bg-primary mt-6 ">
                Learn More
              </button>
            </div>
          </div>
          <div className="  custom-shadow py-4 px-6 text-start max-w-[430px]  bg-primary text-white">
            <div className=" flex justify-between items-center border-b border-white pb-2">
              <h3 className=" max-2xl:text-lg 2xl:text-3xl font-semibold">
                Alpha{' '}
              </h3>
              <h4 className=" 2xl:text-xl max-2xl:text-sm font-semibold">
                4 Month <br />{' '}
                <span className=" 2xl:!text-sm max-2xl:!text-xs text-wite">
                  Duration
                </span>
              </h4>
            </div>
            <div className=" 2xl:text-lg max-2xl:text-sm  font-medium my-2">
              Eligibility: Bachelors 1 , 2, 3, Jrs Cording Lore ipsum dolor sit
              amet
            </div>
            <div className=" text-[10px] mt-3  font-medium text-white my-2 flex gap-1 overflow-x-scroll">
              <button className=" px-2 py-1  bg-cgreensky rounded-full text-nowrap">
                Languages
              </button>
              <button className=" px-2 py-1 bg-cgreensky rounded-full text-nowrap">
                Data structures
              </button>
              <button className=" px-2 py-1 bg-cgreensky rounded-full text-nowrap">
                CSE Subject
              </button>
              <button className=" px-2 py-1 bg-cgreensky rounded-full text-nowrap">
                System Dessign
              </button>
            </div>
            <ul className="my-2 text-white">
              <h4 className=" text-base font-normal text-white">
                Programs Including
              </h4>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-white !text-primary checkbox-white"
                />
                <span className="ml-2">350+ coding questions</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">2 Algorithmic projects</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">1:1 Mock tests and Interviews</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">Instant doubt resolution</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">Resume building</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">
                  Career guidance & Personal feedback
                </span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">Referrals up to 5 companies</span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">Free LinkedIn + Email scripts</span>
              </li>

              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">
                  Free LinkedIn creation & Portfolio setup
                </span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">
                  Access to exclusive MAANG Interview questions
                </span>
              </li>
              <li className=" text-base font-medium my-2 flex items-center ">
                <input
                  type="checkbox"
                  checked
                  className=" h-5 w-5 !rounded-full accent-primary !text-white  checkbox-white"
                />
                <span className="ml-2">Course + Internship certificate</span>
              </li>
            </ul>
            <div className="">
              {' '}
              <button className=" mx-auto text-primary custom-button border-white bg-white mt-6 ">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className=" absolute top-60 -left-10 max-md:hidden">
        {' '}
        <img src={circle} alt="" />
      </div>
      <div className=" absolute top-10 -right-10 max-md:hidden">
        {' '}
        <img src={circle} alt="" />
      </div>
      <img
        src={dottcircle}
        alt=""
        className=" bottom-1/4 absolute -left-10 max-md:hidden "
      />
      <img
        src={dottcircle}
        alt=""
        className=" bottom-28 absolute right-10 max-md:hidden"
      />
    </div>
  );
};

export default ProgramMain;
