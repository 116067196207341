import React from 'react';
import AboutComp from '../../components/about/AboutComp';
import CountingBanner from '../../components/allbanner/CountingBanner';
import LogosBanner from '../../components/allbanner/LogosBanner';
import ChooseComp from '../../components/about/ChooseComp';
import HerosCarasol from '../../components/allcarasol/HerosCarasol';
import NumberCount from '../../components/about/NumberCount';
import MediaCarasol from '../../components/allcarasol/MediaCarasol';
import Testimonial from '../../components/allcarasol/Testimonial';
import DualSection from '../../components/about/DualSection';
import ImageBanner from '../../components/allbanner/ImageBanner';

const AboutPage = () => {
  return (
    <div className="">
      <AboutComp />
      <CountingBanner />
      <LogosBanner />
      <ChooseComp />
      <HerosCarasol />
      <NumberCount />
      <MediaCarasol />
      <Testimonial />
      <DualSection />
      <div className="">
        {' '}
        <ImageBanner />
      </div>
    </div>
  );
};

export default AboutPage;
