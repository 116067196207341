import React, { useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import quates from '../../assets/svg/quates.svg';
import circle from "../../assets/svg/circle.svg"
import dotcircle from "../../assets/svg/dotcircle.svg"
import solidcircle from "../../assets/svg/solidround.svg"
import { useState } from 'react';
import { GetTestimonials } from '../../services/Home.service';
import ring from "../../assets/svg/circle.svg"


const Testimonial = () => {
const [testimonials,setTestimonials]=useState([]);


useEffect(()=>{
  const getTestimonials=async ()=>{
    try{
      const testimonialsData=await GetTestimonials();
      setTestimonials(testimonialsData);
    }catch(err){
      console.log(err)
    }
  }

  getTestimonials();
},[]);

console.log(testimonials);

  return (
    <div className=" relative ">
      <section className=" 2xl:max-w-[1500px] w-[80%] mx-auto ">
        <h6 className=" 2xl:text-3xl text-xl text-primary font-medium">
          Testimonial
        </h6>
        <h4 className="title">Our students Feedback</h4>

        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper my-changes mx-auto !py-10  "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            500: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
        >
          {testimonials?.map((item,idx)=>{
            return   <SwiperSlide key={item?.id} className=' my-auto'>
            <div className=' mx-auto py-10 bg-white custom-shadow  max-w-[600px]'>
            <div className=" mx-auto  flex justify-center items-center flex-col   ">
              <img src={quates} alt="" className=" h-full text-center " />
              <p className=" text-sm text-secondary !py-4 w-[90%] mx-auto">
             {item?.text}
              </p>
            </div>
            <h3 className=" text-base font-medium text-cblack">
              {item?.name}
            </h3>
            <h4 className=" text-sm font-medium text-primary">
              Student, National University
            </h4>
            </div>
        
          </SwiperSlide>
          })}
             <SwiperSlide className=' my-auto'>
            <div className=' mx-auto py-10 bg-white custom-shadow  max-w-[600px]'>
            <div className=" mx-auto  flex justify-center items-center flex-col   ">
              <img src={quates} alt="" className=" h-full text-center " />
              <p className=" text-sm text-secondary !py-4 w-[90%] mx-auto">
                Vitae tortor condimentum lacinia quis vel. Bibendum tristique et
                egestas quis ipsum. Sollicitudin nibh commodo nulla facilisi.
                Velit scelerisque in dictum consectetur a erat nam vestibulum
                morbi blandit cursus risus...
              </p>
            </div>
            <h3 className=" text-base font-medium text-cblack">
              Robert Fox
            </h3>
            <h4 className=" text-sm font-medium text-primary">
              Student, National University
            </h4>
            </div>
        
          </SwiperSlide>
          <SwiperSlide className=' my-auto'>
            <div className=' mx-auto py-10 bg-white custom-shadow  max-w-[600px]'>
            <div className=" mx-auto  flex justify-center items-center flex-col   ">
              <img src={quates} alt="" className=" h-full text-center " />
              <p className=" text-sm text-secondary !py-4 w-[90%] mx-auto">
                Vitae tortor condimentum lacinia quis vel. Bibendum tristique et
                egestas quis ipsum. Sollicitudin nibh commodo nulla facilisi.
                Velit scelerisque in dictum consectetur a erat nam vestibulum
                morbi blandit cursus risus...
              </p>
            </div>
            <h3 className=" text-base font-medium text-cblack">
              Robert Fox
            </h3>
            <h4 className=" text-sm font-medium text-primary">
              Student, National University
            </h4>
            </div>
        
          </SwiperSlide>
       
        </Swiper>
      </section>
      <img src={dotcircle} alt=""  className=' max-md:hidden absolute top-[20%]  z-50 left-[20%]'/>
      <img src={solidcircle} alt=""  className=' max-md:hidden absolute top-[40%]  left-[15%] z-50'/>


      <img src={dotcircle} alt=""  className=' max-md:hidden absolute top-5 right-10'/>
      <img src={ring} alt=""  className=' max-md:hidden absolute bottom-24 -right-10'/>




    </div>
  );
};

export default Testimonial;
