import React from 'react'
import img1 from "../../assets/images/course page.png";
import phone from "../../assets/svg/Rightsidearrow/rightarrowgreen.svg";

const CourseBanner = () => {
  return (
    <div>
         <div className=" mx-auto  sub-section">
            <div className=" w-[98%]  mx-auto rounded-lg relative md:pr-10  h-[170px] overflow-hidden bg-primary  mt-16 max-md:flex-col flex    justify-between gap-4 items-center">
              <div className="md:block hidden">
                {" "}
                <img
                  src={img1}
                  className=" max-w-72 h-10/12  w-full  object-cover relative top-4 "
                  alt=""
                />
              </div>
              <div className=" text-2xl  md:text-start mx-auto max-md:mt-5   font-semibold text-white">
                Affordable online course & Learning <br /> Opportunities for
                you!
              </div>
              <div className=" py-3">
                <button className=" mx-auto text-nowrap  text-sm py-3 px-4 font-semibold rounded-full bg-white text-primary items-center flex gap-2 w-[10/12]">
                  <span>Start Learning Today</span>
                  <img src={phone} alt="" className=" my-auto" />
                </button>
              </div>
              <div className="md:hidden block">
          {' '}
          <img
            src={img1}
            className=" max-w-72 max-h-64 h-full w-full object-cover md:hidden block relative -top-1 "
            alt=""
          />
        </div>
            </div>
          </div>
    </div>
  )
}

export default CourseBanner