import axiosInstance from "./config/ApiConfig";

const GET_BLOG_URL = "/website-management/blogs/";

export const GetBlogs = async () => {
  try {
    const response = await axiosInstance.get(GET_BLOG_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};



export const GetBlog=async (id)=>{
  try{
    const response=await axiosInstance.get(GET_BLOG_URL+id+"/");
    return response.data;
  }
  catch(error){
    throw error;
  }
}