import card1 from '../../assets/images/card/card.png';
import card2 from '../../assets/images/card/card2.png';
import card3 from '../../assets/images/card/card3.png';
import share from '../../assets/svg/share.svg';
import phone from '../../assets/svg/phone/whitephone.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import dottcircle from '../../assets/svg/dotcircle.svg';
import solidround from "../../assets/svg/solidround.svg"

const CardCarasol = () => {
  return (
    <div className="  !w-full  relative">
      <section className="2xl:max-w-[1500px] mx-auto px-5 md:w-[80%] ">
        <h1 className=" title">
          Watch The Placement <br /> Success Stories
        </h1>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper cardcarasol mx-auto py-2  "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            850: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide className="">
            <div className=" my-2 custom-shadow mx-auto p-2 max-w-cardmw  bg-white mt-10 ">
              <div className=" relative">
                <img src={card1} alt="" className=" h-full w-full " />
                <a
                  href=""
                  className=" h-8 w-8 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
                >
                  <img src={share} alt="" />
                </a>
              </div>
              <div className="text-base text-start font-normal p-2">
                <p className=" text-secondary mt-3">
                  <span className=" text-primary">Review</span>: Lorem ipsum
                  dolor adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et magna aliqua...
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className=" ">
            <div className=" custom-shadow mx-auto p-2 my-2 max-w-cardmw  bg-white mt-10 ">
              <div className=" relative">
                <img src={card2} alt="" className=" h-full w-full " />
                <a
                  href=""
                  className=" h-8 w-8 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
                >
                  <img src={share} alt="" />
                </a>
              </div>
              <div className="text-base text-start font-normal p-2">
                <p className=" text-secondary mt-3">
                  <span className=" text-primary">Review</span>: Lorem ipsum
                  dolor adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et magna aliqua...
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" custom-shadow my-2 mx-auto p-2 max-w-cardmw  bg-white mt-10 ">
              <div className=" relative">
                <img src={card3} alt="" className=" h-full w-full " />
                <a
                  href=""
                  className=" h-8 w-8 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
                >
                  <img src={share} alt="" />
                </a>
              </div>
              <div className="text-base text-start font-normal p-2">
                <p className=" text-secondary mt-3">
                  <span className=" text-primary">Review</span>: Lorem ipsum
                  dolor adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et magna aliqua...
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="  ">
            <div className=" custom-shadow my-2 mx-auto p-2 max-w-cardmw  bg-white mt-10 ">
              <div className=" relative">
                <img src={card1} alt="" className=" h-full w-full " />
                <a
                  href=""
                  className=" h-8 w-8 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
                >
                  <img src={share} alt="" />
                </a>
              </div>
              <div className="text-base text-start font-normal p-2">
                <p className=" text-secondary mt-3">
                  <span className=" text-primary">Review</span>: Lorem ipsum
                  dolor adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et magna aliqua...
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="">
            <div className=" my-2 custom-shadow mx-auto p-2 max-w-cardmw  bg-white mt-10 ">
              <div className=" relative">
                <img src={card2} alt="" className=" h-full w-full " />
                <a
                  href=""
                  className=" h-8 w-8 rounded-full bg-white absolute top-5 flex justify-center items-center right-5"
                >
                  <img src={share} alt="" />
                </a>
              </div>
              <div className="text-base text-start font-normal p-2">
                <p className=" text-secondary mt-3">
                  <span className=" text-primary">Review</span>: Lorem ipsum
                  dolor adipiscing elit, sed do eiusmod tempor incididunt ut
                  labore et magna aliqua...
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className=" mt-10 flex justify-center items-center">
          <button className=" custom-button  bg-primary text-white flex items-center gap-2">
            <img src={phone} alt="" className="" />{' '}
            <span>Request a call back</span>
          </button>
        </div>
      </section>

      <img src={dottcircle} alt="" className=" absolute  top-20 max-md:hidden left-16" />
      <img src={dottcircle} alt="" className=" absolute  top-1/3 max-md:hidden  right-20" />
      <img src={solidround} alt="" className=" absolute  top-[50%] max-md:hidden  -right-5" />

    </div>
  );
};

export default CardCarasol;
