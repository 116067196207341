import React from 'react';
import Homeimg from '../../assets/images/girl.png';
import graph from '../../assets/svg/home/graph.svg';
import people from '../../assets/svg/home/people.svg';
import men from '../../assets/svg/home/men.svg';
import question from '../../assets/svg/home/question.svg';
import phone from '../../assets/svg/phone/phone.svg';
import arrowe from '../../assets/svg/home/arrow.svg';
import playbutton from '../../assets/svg/plabuttonsvg.svg';
import sideimg from '../../assets/images/main-sideimg.png';
import dottcircle from "../../assets/svg/dotcircle.svg"
import ring from "../../assets/svg/circle.svg" 

const HomeMain = () => {
  return (
    <div className=" bg-white relative  !max-h-screen max-sm:!bg-bannerbg ">
      <section className="sub-section !bg-white max-sm:!bg-bannerbg">
        <div className=" flex justify-center items-start ">
          <div className=" text-start flex justify-start flex-col gap-4 flex-1 mt-10 ">
            <h6 className=" text-base font-medium text-primary">
              EXPLORE THE LIMITS
            </h6>
            <h1 className=" 2xl:text-f68 !leading-[1.2] max-md:text-2xl lg:text-4xl xl:text-f68 md:text-3xl  font-bold text-cblack">
              Get Your <span className=" text-primary ">Dream Job</span> by <br className=' hidden md:block' />
              learning from our world <br className=' hidden md:block' /> class mentors.
            </h1>
            <p className=" text-xl text-secondary">
              {' '}
              Improve your carer with coding skils.
            </p>
            <div className=" flex items-center gap-4">
              <button className=" custom-button bg-primary text-white max-sm:w-full">
                Explore Programs
              </button>
              <button className=" custom-button text-cblack border-cblack max-sm:w-full flex items-center gap-2">
                <img src={phone} alt="" /> <span>Book a free demo</span>
              </button>
            </div>
            <div className="mt-2 flex gap-2 items-center">
              <button className=" p-2 bg-white shadow-xl h-10 w-10 flex justify-center items-center  rounded-full">
                {' '}
                <img src={playbutton} alt="" />
              </button> 
              <a
                href=" "
                className=" text-xl text-cblack font-bold "
              >
                Play Video{' '}
                <span className=" 2xl:text-base text-xs text-secondary font-mono">
                  (How It Works)
                </span>
              </a>
            </div>
          </div>

          <div className="  max-md:hidden  w-full  flex-1 ">
            {/* <div className=" rounded-full  relative max-lg:h-[250px] max-lg:w-[250px] text-primary h-[330px] w-[350px] border-primary border-2">
              <img
                src={Homeimg}
                alt=""
                className=" absolute -top-14 right-0    "
              />
              <div className=" custom-shadow  flex p-2  gap-1 items-center absolute -left-24 bg-white top-10 ">
                <div className=" bg-corange rounded-md p-2 flex justify-center items-center">
                  <img src={question} alt="" />
                </div>
                <div className=" text-start">
                  <h5 className=" 2xl:text-base text-xs text-cblack font-semibold">
                    1000+ <br /> Coding Questions
                  </h5>
                </div>
              </div>
              <div className=" custom-shadow  flex p-2  gap-1 items-center absolute -right-16 bg-white top-0 ">
                <div className=" bg-cblue rounded-md p-2 flex justify-center items-center">
                  <img src={men} alt="" />
                </div>
                <div className=" text-start">
                  <h5 className=" 2xl:text-base text-xs text-cblack font-semibold">
                    Hybrid <br /> Classes
                  </h5>
                </div>
              </div>
              <div className=" custom-shadow  flex flex-col p-2  gap-1 items-start absolute  -left-8 bg-white -bottom-16 ">
                <div className=" bg-cpurple rounded-md p-2 flex justify-center items-center">
                  <img src={people} alt="" />
                </div>
                <div className=" text-start">
                  <h5 className=" 2xl:text-base text-xs text-cblack font-semibold">
                    Mock <br /> Interviews
                  </h5>
                </div>
              </div>
              <div className=" custom-shadow  flex  p-2  gap-1  absolute -right-28 bg-white bottom-0 ">
                <div className=" bg-primary rounded-md p-2 flex justify-center ">
                  <img src={people} alt="" />
                </div>
                <div className=" text-start">
                  <h5 className=" 2xl:text-base text-xs text-cblack font-semibold">
                    Real Time <br /> Industry <br /> Projects
                  </h5>
                </div>
              </div>
            </div> */}
            <img src={sideimg} alt="" className=" flex-1 w-full" />
          </div>
        </div>
        <div className=" text-center flex justify-center items-center left-10  relative lg:-top-28">
          <img src={arrowe} alt="" />
        </div>
      </section>

      <img src={dottcircle} alt=''  className=' max-md:hidden absolute -left-10 bottom-[30%]'/>
      <img src={ring} alt=''  className=' max-md:hidden absolute -left-10 bottom-[22%]'/>

    </div>
  );
};

export default HomeMain;
