import React from 'react'
import OtpComp from '../../components/otpvarify/OtpVarifyComp'

const OtpVarify = () => {
  return (
    <div>
      <OtpComp/>
    </div>
  )
}

export default OtpVarify
