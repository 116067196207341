import React from "react";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import arrowwhite from "../../assets/svg/whiteArrow.svg";
import { Link } from "react-router-dom";
import resetsuccess from "../../assets/svg/Loginsuccess.svg";
import Button from "../button/Button";

const CreatePasswordComp = () => {
  return (
    <>
      <div className="flex-1 flex flex-col justify-center relative  items-center">
        <form className="p-4  max-auto  flex justify-center items-center  ">
          <div className=" mt-4  w-full md:w-[100%] text-center   ">
            <h4 className=" text-center text-cblack  text-3xl font-semibold ">
              Create your Password
            </h4>{" "}
            <p className=" text-sm text-secondary  text-center  font-normal  mt-2">
              Create your password
            </p>
            <div className=" text-start mt-8">
              <label className=" text-base  text-cblack font-semibold">
                New Password
              </label>{" "}
              <input
                name="email"
                type="text"
                placeholder="Your Full Email"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" text-start mt-4">
              <label className=" text-base  text-cblack font-semibold">
                Confirm Password
              </label>{" "}
              <input
                name="email"
                type="text"
                placeholder="Your Full Email"
                className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
              />
            </div>
            <div className=" mt-8 w-full">
              <Link
                to="/resetsuccess"
                type="submit"
                className=" bg-black text-white flex justify-center  text-xs p-3 font-semibold rounded-full  !w-full  mx-auto"
              >
                Countinue
              </Link>
            </div>
          </div>
        </form>
        {/* <img src={resetsuccess} alt="" width={200} height={200}/>
<h1 className=" text-black font-semibold text-2xl my-3">
Thank You !

</h1>
<h1 className=" text-black font-semibold text-lg ">
Your Password is resetted successfully

</h1>
<h1 className=" text-secondary font-medium  text-sm mt-2 ">
You will redirect to the login page

</h1>
<h1 className=" text-secondary font-medium  text-sm  mt-2">
Redirecting to website in 50 seconds !

</h1>
<h1 className=" text-secondary font-medium  text-xs my-3">
Or

</h1>
<div className=" px-4 py-2 bg-primary text-white text-xs rounded-full">
Log in now 
</div> */}
      </div>
    </>
  );
};

export default CreatePasswordComp;
