import card1 from '../../assets/images/card/card2.png';
import rightArrow from '../../assets/rightarrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import horglass from '../../assets/svg/horglass.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';
import circle from '../../assets/svg/circle.svg';
const MediaCarasol = () => {
  return (
    <div className=" relative bg-white">
      <section className="2xl:max-w-[1500px] mx-auto px-5 w-[80%]">
        <h4 className="title">Media Coverage</h4>
        <p className=" sub-title">Check our latest news</p>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper mx-auto  "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            970: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            <div className="custom-shadow mx-auto max-w-[600px] mt-10   ">
              <img src={card1} alt="" className=' rounded-xl w-full'  />

              <div className="custom-shadow rounded-xl  p-3 w-[90%]  !bg-white  mx-auto relative bottom-10 z-50">
                <div className=" text-start flex  flex-col items-start gap-2">
                  {' '}
                  <input type="date" className=" text-secondary text-sm" />
                  <h4 className="  lg:text-lg md:text-sm text-base !leading-[1] text-cblack font-semibold ">
                    Cursus risus at ultrices mi at the tempus.
                  </h4>
                  <p class="  lg:text-xs md:text-[10px] text-xs  text-secondary font-normal">
                    Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et magna aliqua...
                  </p>
                  <div className=" flex justify-between pt-2 items-center w-full">
                    <div className=" flex gap-2">
                      <img src={horglass} alt="" />
                      <a href="" class=" text-sm font-bold text-cblack">
                        Hourglass
                      </a>
                    </div>

                    <img src={rightArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto max-w-[600px] mt-10   ">
              <img src={card1} alt="" className=' rounded-xl w-full'  />

              <div className="custom-shadow rounded-xl  p-3 w-[90%]  !bg-white  mx-auto relative bottom-10 z-50">
                <div className=" text-start flex  flex-col items-start gap-2">
                  {' '}
                  <input type="date" className=" text-secondary text-sm" />
                  <h4 className="  lg:text-lg md:text-sm text-base !leading-[1] text-cblack font-semibold ">
                    Cursus risus at ultrices mi at the tempus.
                  </h4>
                  <p class="  lg:text-xs md:text-[10px] text-xs  text-secondary font-normal">
                    Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et magna aliqua...
                  </p>
                  <div className=" flex justify-between pt-2 items-center w-full">
                    <div className=" flex gap-2">
                      <img src={horglass} alt="" />
                      <a href="" class=" text-sm font-bold text-cblack">
                        Hourglass
                      </a>
                    </div>

                    <img src={rightArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto max-w-[600px] mt-10   ">
              <img src={card1} alt="" className=' rounded-xl w-full'  />

              <div className="custom-shadow rounded-xl  p-3 w-[90%]  !bg-white  mx-auto relative bottom-10 z-50">
                <div className=" text-start flex  flex-col items-start gap-2">
                  {' '}
                  <input type="date" className=" text-secondary text-sm" />
                  <h4 className="  lg:text-lg md:text-sm text-base !leading-[1] text-cblack font-semibold ">
                    Cursus risus at ultrices mi at the tempus.
                  </h4>
                  <p class="  lg:text-xs md:text-[10px] text-xs  text-secondary font-normal">
                    Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et magna aliqua...
                  </p>
                  <div className=" flex justify-between pt-2 items-center w-full">
                    <div className=" flex gap-2">
                      <img src={horglass} alt="" />
                      <a href="" class=" text-sm font-bold text-cblack">
                        Hourglass
                      </a>
                    </div>

                    <img src={rightArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto max-w-[600px] mt-10   ">
              <img src={card1} alt="" className=' rounded-xl w-full'  />

              <div className="custom-shadow rounded-xl  p-3 w-[90%]  !bg-white  mx-auto relative bottom-10 z-50">
                <div className=" text-start  flex  flex-col items-start gap-2">
                  {' '}
                  <input type="date" className=" text-secondary text-sm" />
                  <h4 className="  lg:text-lg md:text-sm text-base !leading-[1] text-cblack font-semibold ">
                    Cursus risus at ultrices mi at the tempus.
                  </h4>
                  <p class="  lg:text-xs md:text-[10px] text-xs  text-secondary font-normal">
                    Lorem ipsum dolor sit amet, adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et magna aliqua...
                  </p>
                  <div className=" flex justify-between pt-2 items-center w-full">
                    <div className=" flex gap-2">
                      <img src={horglass} alt="" />
                      <a href="" class=" text-sm font-bold text-cblack">
                        Hourglass
                      </a>
                    </div>

                    <img src={rightArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          
        
        </Swiper>
      </section>
      <img
        src={circle}
        alt=""
        className=" absolute top-[30%] -right-8 max-md:hidden"
      />
      <img
        src={dottcircle}
        alt=""
        className=" absolute top-40 left-16 max-md:hidden"
      />
    </div>
  );
};

export default MediaCarasol;
