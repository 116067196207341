import React, { useState } from 'react';
import rightarrow from '../../assets/svg/Rightsidearrow/rightarrowgreen.svg';
import dottedcircle from '../../assets/svg/whitdotcircle.svg';
import greendot from '../../assets/svg/dotcircle.svg';
import phone from '../../assets/svg/phone/bluephone.svg';
import FreeCall from '../../pages/freecall/FreeCall';

const WorkBanner = () => {
  const [freecall, setFreeCall] = useState(false);

  const OpenModel = () => {
    setFreeCall(!freecall);
  };
  return (
    <div className=" relative">
      <div className=" sub-section">
        <div className="  bg-primary  rounded-lg py-8 px-6 flex justify-between items-center max-sm:flex-col">
          <div className=" text-white text-start">
            <h5 className=" 2xl:text-xl max-2xl:text-lg font-normal">
              Enrollment PRocess
            </h5>
            <h1 className=" 2xl:text-5xl max-2xl:text-3xl font-semibold my-2 max-sm:text-xl">
              How to join a program
            </h1>
            <h3 className=" 2xl:text-lg max-2xl:text-sm md:w-[55%]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </h3>
          </div>
          <div className=" relative">
            <img src={dottedcircle} alt="" />
            <img
              src={dottedcircle}
              alt=""
              className=" absolute -top-8 left-12"
            />
          </div>
          <button className=" custom-button bg-white text-primary flex gap-2 w-[10/12]">
            <img src={phone} alt="" />
            <button onClick={OpenModel}>
              <span>Request a call back</span>
            </button>
          </button>
        </div>
      </div>
      <img src={greendot} alt="" className=" right-0 absolute top-0" />
      {freecall && (
        <div className=" absolute  ">
          <FreeCall />
        </div>
      )}
    </div>
  );
};

export default WorkBanner;
