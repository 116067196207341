import React from 'react';
import ProfileComp from '../../components/profile/ProfileComp';

const ProfilePage = () => {
  return (
    <div className="">
      <ProfileComp />
    </div>
  );
};

export default ProfilePage;
