import React, { useEffect, useState } from "react";
import certificate1 from "../../assets/images/certificatesimages/certificate.png";
import share from "../../assets/svg/share.svg";
import star from "../../assets/svg/star.svg";
import days from "../../assets/svg/days.svg";
import startpoint from "../../assets/svg/startpoint.svg";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";

import file from "../../assets/svg/file.svg";
import medal from "../../assets/svg/Group 37433medal.svg";
import { Link } from "react-router-dom";
import { useUserStore } from "../../store/store";
import { useCookie } from "react-use";
import { GetUserBatch } from "../../services/Course.service";

const CourseCard = ({ course }) => {
  const user = useUserStore((state) => state.user);
  const [batches, setBatches] = useState([]);
  const [loggedIn, ,] = useCookie("maang");

  useEffect(() => {
    const getUserBatch = async () => {
      try {
        const batchData = await GetUserBatch();
        setBatches(batchData?.results);
      } catch (err) {
        console.log(err);
      }
    };
    if (loggedIn) getUserBatch();
  }, [loggedIn]);

  return (
    <div className=" custom-shadow mx-auto p-2  max-w-[350px] mt-10 ">
      <div className=" relative h-[150px] ">
        <img
          src={course.thumbnail}
          alt=""
          className=" h-full w-full !object-cover rounded-md "
        />
        <a
          href=""
          className=" h-6 w-6 rounded-full bg-white absolute top-3 flex justify-center items-center right-3"
        >
          <img src={share} alt="" />
        </a>
        <img src={medal} alt="" className=" absolute top-3 left-3" />
      </div>
      <div className=" text-start p-2 ">
        <div className=" flex justify-between items-center">
          <h4 className=" text-[10px] bg-clightbg px-2 py-1 rounded-full font-medium  text-secondary">
            Software Development
          </h4>
          <button className=" rounded-full  !py-1 text-white gap-1 bg-primary flex justify-between items-center text-xs !px-2">
            <img src={star} alt="" />
            <span>4.9</span>
          </button>
        </div>
        <h3 className=" text-base mt-2 font-semibold text-cblack">
          {course.name}
        </h3>
        <ul className="flex justify-between items-center my-2 pr-4">
          <li className=" flex gap-2  ">
            <img src={days} alt="" />
            <span className=" font-medium text-xs text-secondary">
              {course.course_duration} Month
            </span>
          </li>
          <li className=" flex gap-2 ">
            <img src={startpoint} alt="" />
            <span className=" font-medium text-xs text-secondary">
              Lorem ispum
            </span>
          </li>
        </ul>
        <ul className="flex justify-between items-center my-2 pr-4">
          <li className=" flex gap-2  ">
            <img src={startpoint} alt="" />
            <span className=" font-medium text-xs text-secondary">3 Month</span>
          </li>
          <li className=" flex gap-2 ">
            <img src={file} alt="" />
            <span className=" font-medium text-xs text-secondary">
              Hybrid Classes
            </span>
          </li>
        </ul>
        <div className=" mt-3">
          <h4 className="text-base font-bold">₹{course.price}</h4>
          <div className=" mt-2 flex  justify-between w-full  ">
            <Link
              to={"/course/" + course.id}
              className=" text-nowrap rounded-full py-1  text-[10px] !font-medium border-primary text-primary !border !px-5"
            >
              Learn more
            </Link>
            {Object.keys(user)?.length <= 1 ? (
              <button
                onClick={() => (window.location.href = "/login")}
                className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5"
              >
                Enroll Now
              </button>
            ) : batches?.some((e) => e?.course?.id === course?.id) ? (
              <button
                onClick={() => (window.location.href = "/timetable")}
                className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5"
              >
                Time Table
              </button>
            ) : (
              // Open the payment modal if logged in and course is not in batch
              <button className="  text-nowrap rounded-full py-1  !font-medium text-[10px] border-primary text-white bg-primary !border !px-5">
                Enroll Now
              </button> 

            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
