import React from 'react';

import spheral from '../../assets/svg/reconisged/spheral.svg';
import luminos from '../../assets/svg/reconisged/luminos.svg';
import focalpoint from '../../assets/svg/reconisged/focal.svg';
import capsule from '../../assets/svg/reconisged/capsule.svg';

const ReconisedBanner = () => {
  return (
    <>
      <section className=" bg-bannerbg">
        <div className=" sub-section">
          <h4 className=" title">Recognised by</h4>
          <ul className=" text-lg font-bold text-secondary mt-10 flex sm:grid grid-cols-5 max-sm:grid-cols-3 justify-between items-center 2xl:gap-10 md:gap-5  gap-5">
            <li className="px-2">
              <img src={capsule} alt="" />
            </li>
            <li className="px-2">
              <img src={spheral} alt="" />
            </li>
            <li className="px-2">
              <img src={luminos} alt="" />
            </li>
            <li className="px-2 sm:block hidden">
              <img src={focalpoint} alt="" />
            </li>
            <li className="px-2 sm:block hidden">
              <img src={spheral} alt="" />
            </li>
          </ul>
          <ul className='text-lg font-bold text-secondary mt-5 flex  justify-center items-center 2xl:gap-10 md:gap-5 '>
          <li className="px-2 sm:hidden block flex-1 ">
              <img src={focalpoint} alt=""  className='mx-auto'/>
            </li>
            <li className="px-2 sm:hidden block flex-1">
              <img src={spheral} alt="" className='mx-auto' />
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default ReconisedBanner;
