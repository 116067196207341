import DatePickerComp from "../datepicker/DatePickerComp";
import React, { useEffect, useState } from "react";

import star from "../../assets/svg/star.svg";
import days from "../../assets/svg/days.svg";
import fillup from "../../assets/svg/file.svg";
import hybrid from "../../assets/svg/file.svg";
import AcordionComp from "../accordion/AcordionComp";
import img1 from "../../assets/images/bookimg.png";
import tikmark from "../../assets/svg/greytikmark.svg";
import { useUserStore } from "../../store/store";
import { useParams } from "react-router-dom";
import { GetCourse, GetUserBatch } from "../../services/Course.service";
import { useCookie } from "react-use";

const BookCoursecomp = () => {
  const { id } = useParams();
  const user = useUserStore((state) => state.user);
  const [loggedIn, ,] = useCookie("maang");
  const [course, setCourse] = useState({});
  const [batches, setBatches] = useState([]);

  useEffect(() => {
    const getCourse = async () => {
      try {
        const data = await GetCourse(id);
        setCourse(data);
        if (loggedIn) {
          let batchData = await GetUserBatch();
          setBatches(batchData.results);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getCourse();
  }, [loggedIn, id]);

  console.log("Course", course);

  const req = course?.requirements;
  const discountedPrice = course
    ? course.price - (course.price * course.discount_percentage) / 100
    : 0;


   
  return (
    <div className=" relative -top-36 ">
      <div className="bg-cnavy h-[500px] max-md:h-[600px] "></div>
      <section className=" sub-section grid grid-cols-12 -mt-[280px] gap-3 relative   -top-24  max-md:-top-48  ">
        <div className=" md:col-span-8  col-span-12 md:w-11/12 w-full">
          <div className=" max-2xl:top-16 max-sm:top-2 relative">
            <div className=" text-start ">
              <div className=" text-primary font-normal 2xl:text-xl max-2xl:text-sm flex gap-4">
                <a href="">Program </a>
                <a href="">All Course </a>
                <a href="">Interview Preparation course (alpha) </a>
              </div>
              <div className=" w-10/12">
                <h1 className=" title my-2 !text-white">{course?.name}</h1>
                <p className=" text-white 2xl:text-lg max-2xl:text-xs pr-10">
                  {course?.short_description}
                </p>
              </div>
              <div className=" mt-3 flex justify-start items-center gap-4">
                <button className="custom-button bg-transparent border-none custom-shadow  !py-1 text-white  flex justify-between items-center 2xl:!text-base  max-2xl:!text-xs !px-2">
                  Preparation
                </button>
                <button className="custom-button  border-primary  !py-1 text-white gap-1 bg-primary flex justify-between items-center  !px-2">
                  <img src={star} alt="" />
                  <span>4.9</span>
                </button>
              </div>
              <ul className="mt-3 grid grid-cols-4 max-md:grid-cols-2 gap-3 justify-between items-center">
                <li className="text-white 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2">
                  {" "}
                  <img src={days} alt="" />
                  <span>{course.course_duration} Weeks</span>
                </li>
                <li className="text-white 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2">
                  {" "}
                  <img src={fillup} alt="" />
                  <span>Lorem ispum</span>
                </li>
                <li className="text-white 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2">
                  {" "}
                  <img src={fillup} alt="" />
                  <span>Lorem ispum</span>
                </li>
                <li className="text-white 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2">
                  {" "}
                  <img src={hybrid} alt="" />
                  <span>Hybrid Classes</span>
                </li>
              </ul>
              <div className=" text-start  py-10 md:hidden block w-full   ">
                {" "}
                <div className=" flex items-start  gap-2 md:py-5 ">
                  <h4 className=" text-white text-xl font-bold">
                    ₹{discountedPrice}
                  </h4>
                  <h3 className=" text-sm font-medium text-secondary">
                    ₹{course.price} <br />
                    <span className=" text-primary ml-2">50% Off</span>
                  </h3>
                </div>
                <div className="flex gap-2 md:w-10/12 overflow-x-scroll max-md:mt-10">
                  <button className=" custom-button bg-primary border-primary text-white flex-1">
                    Buy this course
                  </button>
                  <button className=" custom-button text-primary  bg-white flex-1">
                    Share
                  </button>
                </div>
              </div>
            </div>
            <div className=" md:mt-24 max-md:mt-16  py-5 text-start font-sans">
              <h4 className=" 2xl:text-2xl font-medium max-2xl:text-xl">
                What Will You learn?
              </h4>
              <ul className="mt-3 list-disc  grid grid-cols-2 gap-3 ">
                <li className="   max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
                <li className="    max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
                <li className="   max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
                <li className="  max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
                <li className="  max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
                <li className="  max-2xl:text-sm 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                  Lorem ipsum dolor sit amet, consecte adipielit, sed do eiusmod
                  tempor incididunt{" "}
                </li>
              </ul>
              <div className=" mt-10">
                <h4 className=" 2xl:text-2xl font-medium max-2xl:text-xl">
                  Description
                </h4>
                <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
                  {course.description}
                </p>
              </div>
              <div className=" mt-10">
                <h4 className=" 2xl:text-2xl font-medium max-2xl:text-xl">
                  Requirements
                </h4>
                <ul className="mt-5  grid  grid-cols-4 max-md:grid-cols-2 gap-3 items-center">
                  <li className="text-secondary flex-1 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2">
                    {" "}
                    <img src={days} alt="" />
                    <span>Laptop</span>
                  </li>
                  {req &&
                    req.split("\n").map((item, i) => (
                      <li
                        key={i}
                        className="text-secondary flex-1 2xl:text-lg max-2xl:text-xs font-medium flex justify-start items-center gap-2"
                      >
                        {" "}
                        <img src={fillup} alt="" />
                        <span>{item}</span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className=" !pl-0 text-start font-sans mt-20 w-full">
            <h4 className=" 2xl:text-2xl font-medium max-2xl:text-xl">
              Course content
            </h4>
            <div className=" md:pl-10 mt-5 w-full">
              <AcordionComp syllabus={course?.syllabi} />
            </div>
          </div>
        </div>
        <div className=" md:col-span-4 font-sans md:mt-28 col-span-12 pt-10">
          <div className=" text-start max-md:hidden   ">
            {" "}
            <img
              src={course?.thumbnail}
              alt=""
              className=" w-[400px] relative  rounded-lg  object-cover h-[260px] max-md:hidden"
            />
            <div className=" flex items-start  gap-2 md:py-5 px-5 ">
              <h4 className=" text-cblack text-xl font-bold">₹20,000</h4>
              <h3 className=" text-sm font-medium text-secondary">
                ₹40,000 <br />
                <span className=" text-primary ml-2">50% Off</span>
              </h3>
            </div>
            <div className="px-5 flex gap-2 w-10/12 overflow-x-scroll">
              <button className=" text-[10px] lg:text-sm py-1 px-2 rounded-full  font-medium text-nowrap bg-primary text-white flex-1">
                Buy this course
              </button>
              <button className=" text-[10px] border  lg:text-sm py-1 px-2 rounded-full  font-medium text-nowrap text-primary border-primary flex-1">
                Share
              </button>
            </div>
          </div>
          <div className=" text-start mt-5 px-3">
            <h4 className="text-cblack font-sans my-3  w-full 2xl:text-2xl font-medium max-2xl:text-lg">
              This course Including
            </h4>
            <ul className="my-2  text-secondary">
              <li className=" text-base font-normal my-2 flex items-center ">
                <img src={tikmark} alt="" />

                <span className="ml-2">350+ coding questions</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">2 Algorithmic projects</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">1:1 Mock tests and Interviews</span>
              </li>

              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">Instant doubt resolution</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">Resume building</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">
                  Career guidance & Personal feedback
                </span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">Referrals up to 5 companies</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">Free LinkedIn + Email scripts</span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">
                  Free LinkedIn creation & Portfolio setup
                </span>
              </li>
              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">
                  Access to exclusive MAANG Interview questions
                </span>
              </li>

              <li className=" text-base font-normal my-2 flex items-center ">
                <label className=" h-5 w-5">
                  <img src={tikmark} alt="" />
                </label>

                <span className="ml-2">Course + Internship certificate</span>
              </li>
            </ul>
          </div>
          <div className=" mt-5 md:text-start text-center max-md:hidden">
            <h4 className="text-cblack max-md:hidden font-sans my-3 md:text-start !text-center  w-full 2xl:text-2xl font-medium max-2xl:text-lg">
              This course Including
            </h4>
            <p className=" text-base font-normal my-2 flex items-center text-secondary">
              Lorem ipsum dolor sit amet, consecte Lorem ipsum dolor sit amet,
              consecte{" "}
            </p>
          </div>
          <div className=" mt-5">
            <DatePickerComp />
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookCoursecomp;
