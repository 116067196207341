import React, { useState } from "react";
import {
  ControlledAccordion,
  AccordionItem,
  useAccordionProvider,
  useAccordionState,
} from "@szhsin/react-accordion";

const AcordionComp = ({ syllabus }) => {
  console.log("Syllabus",syllabus)
  const sortedSyllabus = syllabus?.sort((a, b) => {
    const aWeekNumber = a.name.match(/\d+/);
    const bWeekNumber = b.name.match(/\d+/);
  
    if (!aWeekNumber && !bWeekNumber) {
      return 0;
    } else if (!aWeekNumber) {
      return 1;
    } else if (!bWeekNumber) {
      return -1;
    } else {
      const aParsedNumber = parseInt(aWeekNumber[0]);
      const bParsedNumber = parseInt(bWeekNumber[0]);
      return aParsedNumber - bParsedNumber;
    }
  });
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    // transition: true,
    // transitionTimeout: 250,
    unmountOnExit: false,
  });

  return (
    <div>
      <ControlledAccordion providerValue={providerValue}>
        {sortedSyllabus?.map((item, index) => (
          <CustomAccordionComponent item={item} index={index} />
        ))}
      </ControlledAccordion>
    </div>
  );
};

const CustomAccordionComponent = ({ index, item }) => {
  const { getItemState } = useAccordionState();

  return (
    <AccordionItem
      itemKey={`item-${index}`}
      key={index}
      header={
        <>
          {item?.name}
          <span
            className={`ml-4 h-6 w-6 p-2 rounded-full border flex justify-center text-xl items-center ${
              getItemState(`item-${index}`).isEnter
                ? "text-white bg-primary"
                : "text-primary bg-white border-primary"
            }`}
          >
            {getItemState(`item-${index}`).isEnter ? "-" : "+"}
          </span>
        </>
      }
      className="text-cblack font-sans my-3 border-b border-cborder pb-2 w-full 2xl:text-2xl font-medium max-2xl:text-lg"
    >
      <ul className="mt-3 list-disc">
        {item?.lessons.map((lesson, idx) => {
          return (
            <li className="max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
              {lesson?.name}
            </li>
          );
        })}
      </ul>
    </AccordionItem>
  );
};

export default AcordionComp;
