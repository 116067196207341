import React from "react";

import img from "../../assets/images/Loginsuccess.svg";
import circle from "../../assets/svg/circle.svg";
import { Link } from "react-router-dom";

const DetailSubmit = () => {
  return (
    <>
      <div className=" relative">
        <section className=" sub-section">
          <div className=" flex flex-col justify-center items-center gap-3">
            <img src={img} alt="" />
            <h4 className=" 2xl:text-5xl sm:text-3xl font-semibold max-sm:text-lg">
              Thank You !
            </h4>
            <h5 className=" font-semibold 2xl:text-3xl sm:text-lg max-sm:text-base ">
              Your details has been submited
            </h5>
            <p className=" 2xl:text-xl sm:text-xs max-sm:text-sm font-normal text-secondary leading-1">
              We will get tin touch with you in 1-2 business days <br />
              Redirect to website in 50 seconds !
            </p>
            <Link
              to="/"
              className=" custom-button text-white bg-primary border-primary"
            >
              Go Back Home
            </Link>
          </div>
        </section>
        <img src={circle} alt="" className=" absolute top-2/4 -left-10" />
        <img src={circle} alt="" className=" absolute top-2/4 -right-10" />
      </div>
    </>
  );
};

export default DetailSubmit;
