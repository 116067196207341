import React from 'react'
import ResetSuccessComp from '../../components/resetsuccess/ResetSuccess'

const ResetSuccess = () => {
  return (
    <>
    <ResetSuccessComp/>
      
    </>
  )
}

export default ResetSuccess
