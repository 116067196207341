import axiosInstance from "./config/ApiConfig";

const GET_TESTIMONIAL_URL="/website-management/testimonials/"
const GET_MENTORS_URL="/website-management/mentors/";
const CREATE_CONTACT_URL="/crm/create-contact/";


export const GetTestimonials=async ()=>{
    try{
        const response=await axiosInstance.get(GET_TESTIMONIAL_URL);
        return response.data;
    }catch(error){
        throw error;
    }
}


export const GetMentors=async ()=>{
    try{
        const response=await axiosInstance.get(GET_MENTORS_URL);
        return response.data;
    }catch(error){
        throw error;
    }
}


export const CreateContact=async (payload)=>{
    try{
        const response=await axiosInstance.post(CREATE_CONTACT_URL,payload);
        return response.data;
    }catch(error){
        throw error;
    }
}