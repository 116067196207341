import React from "react";
import img1 from "../../assets/images/blog/blog1.png";
import editimg from "../../assets/svg/tabler_edit.svg";
import { useUserStore } from "../../store/store";

const ProfileComp = () => {
  const { user } = useUserStore((state) => state.user);
  return (
    <div>
      <section className="sub-section">
        <p className=" 2xl:text-2xl md:text-lg     font-semibold max-md:text-2xl mt-2 text-start">
          My Profile
        </p>
        <div className=" flex justify-between items-center border-2 border-cborder p-4 mt-5 rounded-lg">
          <div className=" mt-1 text-start flex justify-start items-center gap-2">
            <img
              src={img1}
              alt=""
              className=" h-12 w-12 rounded-full object-cover"
            />
            <h4 className="  font-semibold text-lg text-cblack !leading-[1]">
              {user?.first_name + " " + user?.last_name} <br />{" "}
              <span className="text-secondary text-sm  font-medium">
                New demo city, New Street, UK
              </span>{" "}
            </h4>
          </div>
          <button class="!px-3 !shrink-0 !py-1 flex h-[30px] justify-start items-center gap-1  rounded-full font-semibold text-sm text-secondary border border-secondary">
            Edit{" "}
            <span>
              <img src={editimg} alt="" />
            </span>
          </button>
        </div>
        <div className=" mt-10 border-cborder border-2  rounded-xl p-4">
          <div className="  flex justify-between">
            <p className=" 2xl:text-2xl md:text-lg font-semibold max-md:text-2xl mt-2 text-start">
              Personal Information
            </p>
            <button class="!px-3 !shrink-0 !py-1 flex h-[30px] justify-start items-center gap-1  rounded-full font-semibold text-sm text-secondary border border-secondary">
              Edit{" "}
              <span>
                <img src={editimg} alt="" />
              </span>
            </button>
          </div>
          <form className=" mt-2 sm:w-8/12 font-sans ">
            <div className=" mt-4 flex gap-5 justify-between items-start">
              <div className=" text-start mt-2 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  First Name
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  {user.first_name}
                </h4>
              </div>
              <div className=" text-start mt-2 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Last Name
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your Last Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  {user.last_name}
                </h4>
              </div>
            </div>
            <div className=" mt-2 flex gap-5 justify-between items-start">
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Email
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  {user.email}
                </h4>
              </div>
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Phone Number
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  {user.student?.phone_num}
                </h4>
              </div>
            </div>
            <div className=" mt-2 flex gap-5 justify-between items-start">
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Location
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  New demo city, New Street, UK
                </h4>
              </div>
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Student
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  1st Year
                </h4>
              </div>
            </div>
            <div className=" mt-2 flex gap-5 justify-between items-start">
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  University
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  National University UK
                </h4>
              </div>
              <div className=" text-start mt-1 flex-1">
                <label className=" text-lg text-secondary font-semibold">
                  Course Name
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  Interview Preparation Course
                </h4>
              </div>
            </div>
            <div className=" mt-2 flex gap-5 justify-between items-start">
              <div className=" text-start mt-1">
                <label className=" text-lg text-secondary font-semibold">
                  Password
                </label>{" "}
                <h4
                  type="text"
                  placeholder="Your First Name"
                  className=" text-cblack text-sm   font-medium   mt-1  w-full"
                >
                  Jane
                </h4>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default ProfileComp;
