import React from 'react';
import rightarrow from '../../assets/svg/Rightsidearrow/rightarrowgreen.svg';
import dottedcircle from '../../assets/svg/whitdotcircle.svg';
import greendot from '../../assets/svg/dotcircle.svg';

const Banner = () => {
  return (
    <div className=" relative">
      <div className=" sub-section">
        <div className="  bg-primary  rounded-lg py-8 px-6 flex justify-between items-center max-sm:flex-col">
          <div className=" text-white text-start">
            <h5 className=" 2xl:text-xl max-2xl:text-lg font-normal">
              Explore Programs
            </h5>
            <h1 className=" 2xl:text-5xl max-2xl:text-2xl font-semibold my-2 max-sm:text-xl">
              Our Premium Programs
            </h1>
            <h3 className=" 2xl:text-lg max-2xl:text-sm">
              Skill Up for the top product Based black company
            </h3>
          </div>
          <div className=" relative">
            <img src={dottedcircle} alt="" />
            <img
              src={dottedcircle}
              alt=""
              className=" absolute -top-8 left-12"
            />
          </div>
          <button className=" custom-button border-white bg-white text-primary">
            <span className="pr-2">View All Programs</span>
            <img src={rightarrow} alt="" />{' '}
          </button>
        </div>
      </div>
      <img src={greendot} alt="" className=" right-0 absolute top-0" />
    </div>
  );
};

export default Banner;
