// PaginationGrid.js
import React, { useState, useEffect } from "react";
import eye from "../../assets/svg/eye.svg";
import message from "../../assets/svg/msg.svg";
import liked from "../../assets/svg/likethumb.svg";
import card2 from "../../assets/images/blog/blog2.png";

import carrer from "../../assets/images/carreer.svg";
import heart from "../../assets/images/heart.svg";
import leftarrow from "../../assets/leftarrow.svg";
import rightarrow from "../../assets/svg/Rightsidearrow/whiteRightarrow.svg";
import { Link } from "react-router-dom";
const cardContent = [
  {
    title: "Sales Caller",
    location: "USA",
    description:
      "Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod",
    positions: ["2 Position", "Full Time", "Onsite", "Full Time"],
  },
];
const BlogReadPagination = ({ blogs }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Assuming 3 items per page

  // Calculate total pages dynamically based on blogs array length
  const totalPages = Math.ceil(blogs?.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, blogs?.length);

  const currentBlogs = blogs?.slice(startIndex, endIndex);

  console.log(currentBlogs);

  // Determine the heading based on the current page
  const currentPageTitle = currentPage === 1 ? null : `Page ${currentPage}`;

  // Generate an array of indices for the cards to render on the current page
  const cardIndices = Array.from({ length: 9 }).map((_, index) => index);

  // Render all cards on the first page
  const renderAllCardsOnFirstPage =
    currentPage === 1 && cardIndices.length === cardContent.length;

  const getDate = (dateString) => {
    const date = new Date(dateString);

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const formattedDate = `${
      months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;

    return formattedDate;
  };

  return (
    <div>
      {currentPageTitle && <h2>{currentPageTitle}</h2>}
      <div className="sm:grid grid-cols-3 3xl:max-w-[1500px] w-[80%] mx-auto py-10 career-portal-cards  p-2 flex justify-start items-center  max-w-[95%]  !overflow-x-scroll  gap-4">
        {currentBlogs?.map((blog, index) => (
          <div
            key={blog.id}
            className="custom-shadow mx-auto w-full shrink-0  2xl:max-w-cardmw max-w-mw350 mt-10 font-sans overflow-hidden   "
          >
            <img
              src={blog.photo}
              alt=""
              className="  max-h-[160px] h-full w-full object-cover rounded-xl"
            />

            <div className="custom-shadow max-w-96 p-3    !bg-white  mx-auto relative z-50">
              <div className=" text-start flex  flex-col items-start">
                {" "}
                <h3 className="2xl:text-base max-md:text-xs md:text-xs text-secondary font-normal">
                  General {getDate(blog?.date)}
                </h3>
                <h4 className=" 2xl:text-2xl md:text-sm lg:text-base max-md:text-sm text-cblack font-semibold ">
                  {blog?.title}
                </h4>
                <p class=" 2xl:text-base max-md:text-xs md:text-xs text-secondary font-normal">
                  {blog?.text?.slice(0, 200) + "..."}
                </p>
                <div className=" flex justify-between items-center w-full mt-2 mt-4">
                  <ul className=" text-xs text-primary flex gap-2 justify-start items-center">
                    <li className=" flex gap-1 items-center">
                      <img src={eye} alt="" /> <span>{blog?.views}</span>
                    </li>
                    <li className=" flex gap-1 items-center ">
                      <img src={message} alt="" /> <span>170 </span>
                    </li>
                    <li className=" flex gap-1 items-center ">
                      <img src={liked} alt="" /> <span>170 </span>
                    </li>
                  </ul>
                  <Link
                     to={"/blog/"+blog?.id}
                    className=" bg-primary px-2 py-1 text-white gap-1 rounded-full text-[8px] font-medium flex items-center"
                  >
                    <span>Read More</span>
                    <img src={rightarrow} alt="" className=" max-h-5 max-w-4" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-6 max-sm:hidden">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="mr-2 p-2 h-8 w-8 bg-primary text-white rounded-full"
        >
          <img src={leftarrow} alt="" />
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i}
            onClick={() => handlePageChange(i + 1)}
            className={`mr-2 p-2 h-8 w-8 text-xs  ${
              currentPage === i + 1
                ? " border border-primary rounded-full text-primary"
                : " text-secondary"
            }`}
          >
            {i + 1}
          </button>
        ))}
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="ml-2 p-2 h-8 w-8 flex justify-center items-center bg-primary text-white rounded-full"
        >
          <img src={rightarrow} alt="" />
        </button>
      </div>
    </div>
  );
};

export default BlogReadPagination;
