import React from 'react'
import CreatePasswordComp from '../../components/createpassword/CreatePassword'

const CraetePassword = () => {
  return (
    <div>
        <CreatePasswordComp/>
      
    </div>
  )
}

export default CraetePassword
