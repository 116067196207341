import React, { useEffect, useState } from "react";
import BlogDetail from "../../components/blog/BlogDetail";
import Banner from "../../components/Program/Banner";
import SearchBar from "../../components/searchbar/SearchBar";
import TabsComp from "../../components/Tabs/TabsComp";
import BlogsCards from "../../components/blog/BlogsCards";
import WaveBanner from "../../components/blog/WaveBanner";
import BlogReadPagination from "../../components/allcarasol/BlogReadPagination";
import { GetBlogs } from "../../services/Blog.service";

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogData = await GetBlogs();
        const sortedBlogs = blogData.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        setBlogs(sortedBlogs);
      } catch (err) {
        console.log(err);
      }
    };

    getBlogs();
  }, []);

  return (
    <div className=" pb-10">
      <WaveBanner />
      <div className=" relative -top-5">
        {" "}
        <SearchBar />
      </div>
      <TabsComp />

      <BlogDetail blogs={blogs} />
      <BlogReadPagination blogs={blogs} />
    </div>
  );
};

export default BlogPage;
