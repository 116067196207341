import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import arrowwhite from "../../assets/svg/whiteArrow.svg";

const AuthLayout = () => {
  return (
    <div>
      <section className=" sub-section md:h-[85vh] overflow-hidden   p-2 bg-white flex justify-around gap-14 max-md:flex-col ">
        <div className=" bg-cnavy clear-start p-4 flex-1 rounded-md py-14">
          <img src={logo} alt="" className=" px-5" />
          <div className=" text-start flex flex-col justify-start items-start gap-2 text-white pl-10 sm:pl-20 relative max-sm:pt-10 max-lg:top-10  lg:top-[20%]">
            <h1 className=" text-4xl text-start mt-2 !leading-[1.2]  font-bold text-white pr-28 max-md:pr-2">
              Welcome to <br /> Our Community
            </h1>
            <p className=" text-base  font-normal  mt-2">
              Sign Up or login to continue to the website
            </p>
            <div className=" mt-4  ">
              <div className="flex justify-start items-center mt-2">
                <img
                  src={img1}
                  className=" h-10 w-10 rounded-full object-cover z-50"
                  alt=""
                />
                <img
                  src={img2}
                  className=" h-10 w-10 rounded-full object-cover z-40 -ml-2"
                  alt=""
                />
                <img
                  src={img3}
                  className=" h-10 w-10 rounded-full object-cover z-30 -ml-2"
                  alt=""
                />
                <span className=" ml-2 text-base font-normal ">
                  10,000+ Users
                </span>
              </div>
              <div className="mt-4  text-sm font-normal ">
                EXPLORE THE LIMITS
              </div>
            </div>
            <img
              src={arrowwhite}
              alt=""
              className=" relative ml-auto lg:-top-20 right-14 lg:"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center relative">
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default AuthLayout;
