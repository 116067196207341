import React from 'react';
import BookCoursecomp from '../../components/bookcourse/BookCoursecomp';
import Banner from '../../components/Program/Banner';

const BookCoursePage = () => {
  return (
    <div className="">
      <BookCoursecomp />
    </div>
  );
};

export default BookCoursePage;
