import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header/Header";

import Home from "./pages/Home/Home";
import Footer from "./components/footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Banner from "./components/Program/Banner";
import Program from "./pages/program/Program";
import Work from "./pages/HowisWork/Work";
import ContactPage from "./pages/contact/ContactPage";
import LoginPage from "./pages/login/LoginPage";
import SignupComp from "./components/Signup/SignupComp";
import ProfilePage from "./pages/profile/ProfilePage";
import DetailSubmit from "./components/detalSubmit/DetailSubmit";
import AboutPage from "./pages/about/AboutPage";
import BookCoursePage from "./pages/bookcourse/BookCoursePage";
import FaqPAge from "./pages/faq/FaqPAge";
import BlogPage from "./pages/blog/BlogPage";
import ReadBlogPage from "./pages/readblogs/ReadBlogPage";
import TermsConditions from "./pages/termsandcondition/TermsConditions";
import FreeCall from "./pages/freecall/FreeCall";
import CareerPortal from "./pages/career/CareerPortal";
import JobDetail from "./pages/jobdetail/JobDetail";
import ApplyApplication from "./pages/applyapplication/ApplyApplication";
import Contest1 from "./pages/contest1/Contest1";
import Contest2 from "./pages/contest2/Contest2";
import Contest3 from "./pages/contest3/Contest3";
import Contest4 from "./pages/contest4/Contest4";
import NextQuestion from "./pages/nextquestion/NextQuestion";
import Contest5 from "./pages/contest5/Contest5";
import Course from "./pages/course/Course";
import { useUserStore } from "./store/store";
import { useCookie } from "react-use";
import { useEffect } from "react";
import ScrollToTop from "./components/utils/ScrollToTop";
import ResetComp from "./components/reset/ResetComp";
import Resetpassword from "./pages/resetpassword/Resetpassword";
import ResetSuccess from "./pages/resetsuccess/ResetSuccess";
import CraetePassword from "./pages/creatpassowrd/CraetePassword";
import OtpComp from "./components/otpvarify/OtpVarifyComp";
import OtpVarify from "./pages/otpvarify/OtpVarify";
import OtpSuccess from "./pages/otpsuccess/OtpSuccess";
import AuthLayout from "./components/layout/AuthLayout";

function App() {
  const updateUser = useUserStore((state) => state.updateUser);
  const user = useUserStore((state) => state.user);
  const removeAllUser = useUserStore((state) => state.removeAllUser);
  const [loggedIn, , deleteLoggedIn] = useCookie("maang");
  const isLoggedIn = !!(loggedIn && Object.keys(loggedIn).length > 0);

  useEffect(() => {
    if (Object.keys(user).length <= 1 && loggedIn)
      updateUser(JSON.parse(loggedIn));
    if (new Date(user.expiry) <= new Date(Date.now())) {
      removeAllUser();
      deleteLoggedIn();
    }
  });
  return (
    <Router>
      <ScrollToTop />
      <div className="App overflow-x-hidden">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/program" element={<Program />} />
          <Route path="/works" element={<Work />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/resetpassword" element={<Resetpassword />} />
          <Route path="/profile" element={<ProfilePage />} />

          <Route path="/" element={<AuthLayout />}>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupComp />} />
            <Route path="/resetsuccess" element={<ResetSuccess />} />
            <Route path="/createpassword" element={<CraetePassword />} />
            <Route path="/otpvarify" element={<OtpVarify />} />
            <Route path="/otpsuccess" element={<OtpSuccess />} />
          </Route>
          <Route path="/detailsubmit" element={<DetailSubmit />} />

          <Route path="/about" element={<AboutPage />} />
          <Route path="/bookcourse" element={<BookCoursePage />} />
          <Route path="/faq" element={<FaqPAge />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blogread" element={<ReadBlogPage />} />
          <Route path="/blog/:id" element={<ReadBlogPage />} />
          <Route path="/termscondition" element={<TermsConditions />} />
          <Route path="/freecall" element={<FreeCall />} />
          <Route path="/careerportal" element={<CareerPortal />} />
          <Route path="/jobdetail" element={<JobDetail />} />
          <Route path="/applyapplication" element={<ApplyApplication />} />
          <Route path="/contest1" element={<Contest1 />} />
          <Route path="/contest2" element={<Contest2 />} />
          <Route path="/contest3" element={<Contest3 />} />
          <Route path="/contest4" element={<Contest4 />} />
          <Route path="/nextquestion" element={<NextQuestion />} />
          <Route path="/contest5" element={<Contest5 />} />
          <Route path="/course" element={<Course />} />
          <Route path="/course/:id" element={<BookCoursePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
