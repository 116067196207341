import React from 'react';
import img1 from '../../assets/images/imagebannergirl.png';
import phone from '../../assets/svg/phone/bluephone.svg';
const ImageBanner = () => {
  return (
    <div className="w-[80%] mx-auto">
      <div className=" rounded-lg relative  top-16 bg-primary p-4 mt-16 max-md:flex-col flex max-h-44 h-full  justify-between gap-4 items-center">
        <div className="">
          {' '}
          <img
            src={img1}
            className=" max-w-48 max-h-64 h-full w-full object-cover relative -top-6 max-md:hidden"
            alt=""
          />
        </div>
        <div className=" 2xl:text-2xl max-2xl:text-xl md:w-2/4   font-medium text-white">
          MAANG your learning journey Book call today and unlock Limitless
          Educations
        </div>
        <button className=" custom-button bg-white text-primary flex gap-2 w-[10/12]">
          <img src={phone} alt="" />
          <span>Book a Call</span>
        </button>
      </div>
    </div>
  );
};

export default ImageBanner;
