import React from 'react'
import dottedcircle from "../../assets/svg/whitdotcircle.svg";
import greendot from "../../assets/svg/dotcircle.svg";
import phone from "../../assets/svg/phone/bluephone.svg";

const CourseMainBanner = () => {
  return (
    <div className=' sub-section mx-auto'>
          <div className="   bg-primary  w-[98%] mx-auto  rounded-lg py-8 px-6 flex justify-between items-center max-sm:flex-col">
              <div className=" text-white text-start">
                <h5 className=" 2xl:text-xl max-2xl:text-lg font-normal">
                  Courses
                </h5>
                <h1 className=" 2xl:text-5xl max-2xl:text-3xl font-semibold my-2 max-sm:text-xl">
                  Premium Courses
                </h1>
                <h3 className=" 2xl:text-lg max-2xl:text-sm md:w-[55%]">
                  Premium Courses
                </h3>
              </div>
              <div className=" relative">
                <img src={dottedcircle} alt="" />
                <img
                  src={dottedcircle}
                  alt=""
                  className=" absolute -top-8 left-12"
                />
              </div>
              <button className=" custom-button bg-white text-primary flex gap-2 w-[10/12]">
                <img src={phone} alt="" />
                <>
                  <span>Request a call back</span>
                </>
              </button>
            </div>
    </div>
  )
}

export default CourseMainBanner