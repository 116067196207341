import React from "react";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import arrowwhite from "../../assets/svg/whiteArrow.svg";
import { Link } from "react-router-dom";
import resetsuccess from "../../assets/svg/Loginsuccess.svg";
import Button from "../button/Button";

const ResetComp = () => {
  return (
    <div>
      <section className=" sub-section !mb-0 md:h-[85vh] overflow-hidden  p-2 bg-white flex justify-around gap-10 max-md:flex-col ">
        <div className=" bg-cnavy clear-start p-4 flex-1 rounded-md py-14">
          <img src={logo} alt="" className=" px-5" />
          <div className=" text-start flex flex-col justify-start items-start gap-2 text-white pl-10 sm:pl-20 relative max-sm:pt-10 max-lg:top-10  lg:top-[20%]">
            <h1 className=" text-4xl text-start mt-2 !leading-[1.2]  font-bold text-white pr-28 max-md:pr-2">
              Welcome to <br /> Our Community
            </h1>
            <p className=" text-base  font-normal  mt-2">
              Sign Up or login to continue to the website
            </p>

            <div className=" mt-4  ">
              <div className="flex justify-start items-center mt-2">
                <img
                  src={img1}
                  className=" h-10 w-10 rounded-full object-cover z-50"
                  alt=""
                />
                <img
                  src={img2}
                  className=" h-10 w-10 rounded-full object-cover z-40 -ml-2"
                  alt=""
                />
                <img
                  src={img3}
                  className=" h-10 w-10 rounded-full object-cover z-30 -ml-2"
                  alt=""
                />
                <span className=" ml-2 text-base font-normal ">
                  10,000+ Users
                </span>
              </div>
              <div className="mt-4  text-sm font-normal ">
                EXPLORE THE LIMITS
              </div>
            </div>
            <img
              src={arrowwhite}
              alt=""
              className=" relative ml-auto lg:-top-20 right-14 lg:"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-center relative  items-center">
          <form className="p-4  max-auto  flex justify-center items-center  ">
            <div className=" mt-4  w-full md:w-[100%] text-center   ">
              <h4 className=" text-start text-cblack  text-3xl font-semibold ">
                Reset Password
              </h4>{" "}
              <p className=" text-sm text-secondary  text-start  font-normal  mt-2">
                Enter your email to reset your password
              </p>
              <div className=" text-start mt-8">
                <label className=" text-base  text-cblack font-semibold">
                  Email
                </label>{" "}
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-full border w-full"
                />
              </div>
              <div className=" mt-8 w-full">
                <Link
                  to="/createpassword"
                  type="submit"
                  className=" bg-black text-white flex justify-center  text-xs p-3 font-semibold rounded-full  !w-full  mx-auto"
                >
                  Countinue
                </Link>
              </div>
            </div>
          </form>
          {/* <img src={resetsuccess} alt="" width={200} height={200}/>
          <h1 className=" text-black font-semibold text-2xl my-3">
          Thank You !

          </h1>
          <h1 className=" text-black font-semibold text-lg ">
          Your Password is resetted successfully

          </h1>
          <h1 className=" text-secondary font-medium  text-sm mt-2 ">
          You will redirect to the login page

          </h1>
          <h1 className=" text-secondary font-medium  text-sm  mt-2">
          Redirecting to website in 50 seconds !

          </h1>
          <h1 className=" text-secondary font-medium  text-xs my-3">
         Or

          </h1>
          <div className=" px-4 py-2 bg-primary text-white text-xs rounded-full">
          Log in now 
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default ResetComp;
