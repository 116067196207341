import axiosInstance from "./config/ApiConfig";

const ALL_COURSES_URL = "/course-management/list-courses/";
const SINGLE_COURSE_URL = "/course-management/list-courses/";
const USER_BATCH_URL = "/course-management/user-batch/";

export const GetAllCourses = async () => {
  try {
    const response = await axiosInstance.get(ALL_COURSES_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetCourse = async (id) => {
  try {
    const response = await axiosInstance.get(SINGLE_COURSE_URL + id + "/");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetUserBatch = async () => {
  try {
    const response = await axiosInstance.get(USER_BATCH_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};
