import React from 'react';
import rightarrow from '../../assets/svg/Rightsidearrow/rightarrowgreen.svg';
import dottedcircle from '../../assets/svg/whitdotcircle.svg';
import greendot from '../../assets/svg/dotcircle.svg';
import phone from '../../assets/svg/phone/bluephone.svg';

const LastBanner = () => {
  return (
    <div className=" relative">
      <div className=" sub-section">
        <div className="  bg-primary  rounded-lg py-8 px-6 flex justify-between items-center max-sm:flex-col">
          <div className=" text-white text-start">
            <h1 className=" text-2xl  font-semibold md:w-[70%] my-2 max-sm:text-xl">
              MAANG your learning journey Book call today and unlock Limitless
              Educations
            </h1>
          </div>
          <div className=" relative">
            <img src={dottedcircle} alt="" />
            <img
              src={dottedcircle}
              alt=""
              className=" absolute -top-8 left-12"
            />
          </div>
          <button className=" custom-button bg-white text-primary flex gap-2 w-[10/12]">
            <img src={phone} alt="" />
            <span>Book a Call</span>
          </button>
        </div>
      </div>
      <img src={greendot} alt="" className=" right-0 absolute top-0" />
    </div>
  );
};

export default LastBanner;
