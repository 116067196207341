import React from 'react'
import OtpSuccessComp from '../../components/otpsuccess/OtpSuccessComp'

const OtpSuccess = () => {
  return (
    <>
    <OtpSuccessComp/>
        
      
    </>
  )
}

export default OtpSuccess
