import React from 'react';
import Homeimg from '../../assets/images/about/about.png';

import dotcircle from '../../assets/svg/dotcircle.svg';
import circle from '../../assets/svg/circle.svg';

const AboutComp = () => {
  return (
    <div className=" relative">
      <section className="sub-section">
        <div className=" flex justify-center items-start max-md:flex-col gap-5 ">
          <div className=" flex-1 text-start flex flex-col gap-4">
            <h6 className=" 2xl:text-2xl font-medium text-primary">About Us</h6>
            <h1 className=" 2xl:text-f68  max-md:text-xl !leading-none md:text-2xl lg:text-4xl font-bold text-cblack  ">
              Transforming the way of learning coding creatively
            </h1>
            <p className=" 2xl:text-3xl text-secondary md:w-3/4">
              {' '}
              We are an ed-tech start-up company founded in August 2021 by a group of employees from MAANG companies to revolutionize the way of learning to code creatively. Our company focuses on engineering students to help them through the interview preparation process to get their dream job.
            </p>
          </div>
          <div className="flex-1">
            <img src={Homeimg} alt="" className=" h-full w-full " />
          </div>
        </div>
      </section>
      <img src={circle} alt="" className=" absolute top-10 -right-10 max-md:hidden" />
      <img src={dotcircle} alt="" className=" absolute top-24 right-12 max-md:hidden" />
    </div>
  );
};

export default AboutComp;
