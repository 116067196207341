import React from 'react';
import FaqDashboard from '../../components/faq/FaqDashboard';
import Banner from '../../components/Program/Banner';
import ImageBanner from '../../components/allbanner/ImageBanner';

const FaqPAge = () => {
  return (
    <div className=" ">
      <Banner />
      <FaqDashboard />
      <div className=' max-md:hidden'>
      <ImageBanner />
      </div>
      
    </div>
  );
};

export default FaqPAge;
