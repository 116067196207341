import React from 'react';
import payment from '../../assets/svg/faq/payemet.svg';
import corse from '../../assets/svg/faq/course.svg';
import Privacy from '../../assets/svg/faq/primary.svg';
import people from '../../assets/svg/faq/people.svg';
import AcordionComp from '../accordion/AcordionComp';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';

const FaqDashboard = () => {
  return (
    <div>
      <section className=" sub-section py-10 grid grid-cols-12 max-md:grid-cols-1 font-sans gap-10 ">
        <div className=" col-span-3 max-md:col-span-1">
          <ul className=" text-start font-sans max-md:max-w-full w-11/12  pr-10">
            <Accordion>
              <AccordionItem
                header={
                  <li className="2xl:text-2xl flex gap-2 w-full items-center p-2 rounded-md font-medium max-2xl:text-base  my-1 text-white bg-primary">
                    <img src={payment} alt="" />
                    <h4 className="">General</h4>
                  </li>
                }
              >
                <ul className="mt-3 list-disc hidden max-md:block ">
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
            <Accordion>
              <AccordionItem
                header={
                  <li className="2xl:text-2xl flex gap-2 w-full items-center p-2 rounded-md font-medium max-2xl:text-base  my-1 text-secondary">
                    <img src={corse} alt="" />
                    <h4 className="">Course</h4>
                  </li>
                }
              >
                <ul className="mt-3 list-disc hidden max-md:block ">
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
            <Accordion>
              <AccordionItem
                header={
                  <li className="2xl:text-2xl flex gap-2 w-full items-center p-2 rounded-md font-medium max-2xl:text-base  my-1 text-secondary">
                    <img src={payment} alt="" />
                    <h4 className="">Payments</h4>
                  </li>
                }
              >
                <ul className="mt-3 list-disc hidden max-md:block ">
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>

            <Accordion>
              <AccordionItem
                header={
                  <li className="2xl:text-2xl flex gap-2 w-full items-center p-2 rounded-md font-medium max-2xl:text-base  my-1 text-secondary">
                    <img src={people} alt="" />
                    <h4 className="">Privacy</h4>
                  </li>
                }
              >
                <ul className="mt-3 list-disc hidden max-md:block ">
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
            <Accordion>
              <AccordionItem
                header={
                  <li className="2xl:text-2xl flex gap-2 w-full items-center p-2 rounded-md font-medium max-2xl:text-base  my-1 text-secondary">
                    <img src={Privacy} alt="" />
                    <h4 className="">Contact Us</h4>
                  </li>
                }
              >
                <ul className="mt-3 list-disc hidden max-md:block ">
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                  <li className="   max-2xl:text-sm my-2 2xl:text-base text-secondary font-normal ml-5 marker:text-primary">
                    C++, java , Paython
                  </li>
                </ul>
              </AccordionItem>
            </Accordion>
          </ul>
        </div>
        <div className=" col-span-9 font-sans text-start max-md:col-span-1 max-md:hidden">
          <div className="2xl:text-2xl flex gap-2 items-center p-2 rounded-md font-medium max-2xl:text-lg text-primary my-1">
            {' '}
            <img src={payment} alt="" /> <span>General</span>
          </div>
          <p className=" my-1 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </p>

          <AcordionComp />
        </div>
      </section>
    </div>
  );
};

export default FaqDashboard;
