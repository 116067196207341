import certificate1 from "../../assets/images/certificatesimages/certificate.png";
import certificate2 from "../../assets/images/certificatesimages/certificate2.png";
import certificate3 from "../../assets/images/certificatesimages/certificate3.png";
import share from "../../assets/svg/share.svg";
import phone from "../../assets/svg/phone/phone.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import star from "../../assets/svg/star.svg";
import days from "../../assets/svg/days.svg";
import startpoint from "../../assets/svg/startpoint.svg";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";

import file from "../../assets/svg/file.svg";
import medal from "../../assets/svg/Group 37433medal.svg";
import { GetAllCourses } from "../../services/Course.service";
import CourseCard from "./CourseCard";

const CardCarasol = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    async function getAllCourses() {
      try {
        const data = await GetAllCourses();
        setCourses(data);
      } catch (err) {
        console.log(err);
      }
    }

    getAllCourses();
  }, []);

  console.log("All Course", courses);
  return (
    <>
      <div className=" 2xl:max-w-[1500px] w-[80%] mx-auto">
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper mx-auto py-10   "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {courses.length > 0 &&
            courses.map((course) => {
              return (
                <SwiperSlide key={course.id}>
                  <CourseCard course={course} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
};

export default CardCarasol;
