import React from 'react'
import payment from '../../assets/svg/faq/payemet.svg';
import corse from '../../assets/svg/faq/course.svg';
import Privacy from '../../assets/svg/faq/primary.svg';
import people from '../../assets/svg/faq/people.svg';

const TermsCondComp = () => {
  return (
    <>
      <div className="  custom-shadow px-5 ">
            <div className="2xl:text-2xl flex gap-2 items-center p-2 rounded-md font-medium max-2xl:text-xl text-primary my-1">
              {' '}
              <img src={payment} alt="" /> <span>Terms & Conditions</span>
            </div>
            <p className=" my-1 max-2xl:text-sm 2xl:text-base text-secondary font-normal">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>General Terms</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                By accessing and placing an order with MAANG Careers, you
                confirm that you are in agreement with and bound by the terms of
                service contained in the Terms & Conditions outlined below.
                These terms apply to the entire website and any email or other
                type of communication between you and MAANG Careers.
              </p>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>Conditions</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                By accessing and placing an order with MAANG Careers, you
                confirm that you are in agreement with and bound by the terms of
                service contained in the Terms & Conditions outlined below.
                These terms apply to the entire website and any email or other
                type of communication between you and MAANG Careers.
              </p>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>Lerum ispum</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>Conditions</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                By accessing and placing an order with MAANG Careers, you
                confirm that you are in agreement with and bound by the terms of
                service contained in the Terms & Conditions outlined below.
                These terms apply to the entire website and any email or other
                type of communication between you and MAANG Careers.
              </p>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>Lerum ispum</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
            <div class=" mt-5 font-sans border-b pb-2 border-cborder">
              <div className="2xl:text-2xl  font-sans items-center  rounded-md font-medium max-2xl:text-base text-cblack my-1">
                <span>Conditions</span>
              </div>
              <p className=" my-2 max-2xl:text-sm 2xl:text-base text-secondary font-normal font-sans">
                Under no circumstances shall MAANG Careers team be liable for
                any direct, indirect, special, incidental or consequential
                damages, including, but not limited to, loss of data or profit,
                arising out of the use, or the inability to use, the materials
                on this site, even if MAANG Careers team or an authorized
                representative has been advised of the possibility of such
                damages. If your use of materials from this site results in the
                need for servicing, repair or correction of equipment or data,
                you assume any costs thereof.
              </p>
            </div>
          
          </div>
        
    </>
  )
}

export default TermsCondComp