import React from "react";
import img from "../../assets/images/contactleft.png";
import { Link, useNavigate } from "react-router-dom";
import ring from "../../assets/svg/smallring.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CreateContact } from "../../services/Home.service";

const ContactForm = () => {
  const navigate = useNavigate();
  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const {
        email,
        phone: phone_number,
        location,
        firstName,
        lastName,
        message,
      } = values;
      const payload = {
        email,
        name: firstName + " " + lastName,
        message,
        location,
        phone_number,
      };
      await CreateContact(payload);
      navigate("/detailsubmit");
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      location: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .max(10, "Phone number must be less than 10 characters"),
      location: Yup.string().required("Location is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: submitHandler,
  });

  return (
    <>
      <section className=" 2xl:sub-section w-[80%]  mx-auto relative  ">
        <div className=" flex max-sm:flex-col justify-between gap-10 mx-auto">
          <div className=" text-start  relative  flex-1 ">
            <img src={img} alt="" className="  h-[90%] w-full " />
            <div className=" absolute top-0 left-24">
              {" "}
              <h4 className=" 2xl:text-4xl md:text-2xl text-cblack font-semibold ">
                Contact Us
              </h4>
              <h3 className=" 2xl:text-xl md:text-sm md:w-[80%]  text-secondary font-normal">
                Any question? we would be happy to help you!
              </h3>
            </div>
          </div>
          <div className=" border-l border-cborder  flex-1 ">
            <form
              onSubmit={formik.handleSubmit}
              className=" md:w-10/12 md:pl-16  "
            >
              <div className=" flex gap-5">
                <div className=" text-start">
                  <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                    First Name:
                  </label>{" "}
                  <input
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholder="Your first name"
                    className="  2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                  />
                </div>
                <div className=" text-start  ">
                  <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                    Last Name:
                  </label>{" "}
                  <input
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholder="Your first name"
                    className=" 2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                  />
                </div>
              </div>
              <div className=" flex gap-5 mt-2">
                <div className=" text-start  ">
                  <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                    Phone:
                  </label>{" "}
                  <input
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholder="Your Number"
                    className=" 2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                    maxLength={10}
                    onInput={(e) =>
                      (e.currentTarget.value = e.currentTarget.value
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1")
                        .replace(/^0[^.]/, "0"))
                    }
                  />
                </div>
                <div className=" text-start  ">
                  <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                    Location
                  </label>{" "}
                  <input
                    name="location"
                    value={formik.values.location}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    placeholder="Your Adress"
                    className=" 2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                  />
                </div>
              </div>

              <div className=" text-start mt-2 ">
                <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                  Email:
                </label>{" "}
                <input
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  placeholder="youremail@email.com"
                  className=" 2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                />
              </div>
              <div className=" text-start mt-2 ">
                <label className=" 2xl:text-xl md:text-base  text-cslategray font-semibold">
                  Message:
                </label>{" "}
                <textarea
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  cols="30"
                  rows="4"
                  placeholder="Type your message here..."
                  className=" 2xl:text-base md:text-xs font-medium py-2 px-4 outline-none mt-2 border-cgray rounded-md border w-full"
                ></textarea>
              </div>

              <button
                type="submit"
                className=" custom-button text-white bg-primary !rounded-md w-full mt-2"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
