import React from 'react';
import dottcircle from "../../assets/svg/dotcircle.svg"

const CountingBanner = () => {
  return (
    <div className=" bg-white relative">
      <section className="sub-section md:w-[75%] ">
        <div className="  mx-auto grid grid-cols-4 max-sm:grid-cols-2 justify-center items-start 2xl:gap-10 md:gap-5 max-md:flex-wrap gap-5 ">
          <div className=" flex justify-center items-center flex-col">
            <h5 className=" 2xl:text-4xl font-bold max-2xl:text-4xl max-md:text-xl text-center">
              10,000+
            </h5>
            <h6 className=" font-normal text-lg text-secondary text-nowrap mt-2">
              Students Trained
            </h6>
          </div>
          <div className=" flex justify-center items-center flex-col">
            <h5 className="2xl:text-4xl font-bold max-2xl:text-4xl max-md:text-xl">
              99%
            </h5>
            <h6 className=" font-normal text-lg text-secondary text-nowrap mt-2 ">
              Students Satisfaction
            </h6>
          </div>
          <div className=" flex justify-center items-center flex-col">
            <h5 className="2xl:text-4xl font-bold max-2xl:text-4xl max-md:text-xl">
              75+
            </h5>
            <h6 className=" font-normal text-lg text-secondary text-nowrap mt-2 ">
              MAANG Mentors
            </h6>
          </div>
          <div className=" flex justify-center items-center flex-col">
            <h5 className="2xl:text-4xl font-bold max-2xl:text-4xl max-md:text-xl">
              100+
            </h5>
            <h6 className=" font-normal text-lg text-secondary text-nowrap mt-2 ">
              Collab Organizations
            </h6>
          </div>
        </div>
      </section>
      <img src={dottcircle} alt=''  className=' max-md:hidden absolute -right-6 bottom-[30%]'/>
    </div>
  );
};

export default CountingBanner;
