import React from "react";
import logo from "../../assets/images/logo.png";
import img1 from "../../assets/images/blog/blog1.png";
import img2 from "../../assets/images/blog/blog2.png";
import img3 from "../../assets/images/blog/blog3.png";
import arrowwhite from "../../assets/svg/whiteArrow.svg";
import { Link } from "react-router-dom";
import resetsuccess from "../../assets/svg/Loginsuccess.svg";
import Button from "../button/Button";

const OtpComp = () => {
  return (
    <>
      <div className="flex-1 flex flex-col justify-center relative  items-center">
        <form className="p-4  max-auto  flex justify-center items-center  ">
          <div className=" mt-4  w-full md:w-[100%] text-center   ">
            <h4 className=" text-center text-cblack  text-3xl font-semibold ">
              Verify your email OTP
            </h4>{" "}
            {/* <h4 className=" text-center text-cblack  text-3xl font-semibold ">
              Wrong OTP
              </h4>{" "} */}
            {/* <h4 className=" text-center text-cblack  text-3xl font-semibold ">
               OTP time is up
              </h4>{" "} */}
            <p className=" text-sm text-secondary  text-center  font-normal  mt-2 !leading-[1.8]">
              We have send you 6 digit OTP on your email{" "}
              <span className=" text-primary">...jsr.com</span> , <br /> Please
              verify your email.
            </p>
            {/* <p className=" text-sm text-secondary  text-center  font-normal  mt-2 !leading-[1.8]">
         The OTP you submitted is wrong, please try again.
              </p> */}
            {/* <p className=" text-sm text-secondary  text-center  font-normal  mt-2 !leading-[1.8]">
            OTP time is up
              </p> */}
            {/* <p className=" text-sm text-secondary  text-center  font-normal  mt-2 !leading-[1.8]">
           The OTP submition time is up, please try again.
              </p> */}
            <div className=" text-start mt-4">
              <div className=" flex gap-1 justify-center items-center">
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
                <input
                  name="email"
                  type="text"
                  placeholder="Your Full Email"
                  className=" text-cslategray  text-sm font-semibold py-2 px-4 outline-none mt-2 border-cgray rounded-[12px] border  h-[50px] w-[50px]"
                />
              </div>
            </div>
            <p className=" text-sm text-secondary  text-center  font-normal  mt-6 !leading-[1.8]">
              If you didn’t get the OTP you can try request again <br /> after
              60 sec.
            </p>
            <button className=" text-sm text-primary  text-center  font-normal  mt-2">
              Resend OTP.
            </button>
            <div className=" mt-8 w-full">
              <Link
                to="/otpsuccess"
                type="submit"
                className=" bg-black text-white flex justify-center  text-xs p-3 font-semibold rounded-full  !w-full  mx-auto"
              >
                Submit
              </Link>
            </div>
          </div>
        </form>
        {/* <img src={resetsuccess} alt="" width={200} height={200}/>
          <h1 className=" text-black font-semibold text-2xl my-3">
          Thank You !

          </h1>
          <h1 className=" text-black font-semibold text-lg ">
          Your Password is resetted successfully

          </h1>
          <h1 className=" text-secondary font-medium  text-sm mt-2 ">
          You will redirect to the login page

          </h1>
          <h1 className=" text-secondary font-medium  text-sm  mt-2">
          Redirecting to website in 50 seconds !

          </h1>
          <h1 className=" text-secondary font-medium  text-xs my-3">
         Or

          </h1>
          <div className=" px-4 py-2 bg-primary text-white text-xs rounded-full">
          Log in now 
          </div> */}
      </div>
    </>
  );
};

export default OtpComp;
