import axiosInstance from "./config/ApiConfig";

const LOGIN_URL = "/api/login/";
const LOGOUT_URL = "/api/auth/logout/";
const USER_VALID_CHECK_URL = "/test-management/user-valid-check/";
const SIGNUP_URL = "/api/signup/";
const VALIDATE_OTP_URL = "/api/otp/validate/";
const RESEND_OTP_URL = "/api/otp/resend/";
const FORGET_PASSWORD_URL = "/api/password/forget/";
const VERIFY_OTP_URL = "/api/password/verify/";
const SUBMIT_PASSWORD_RESET_URL = "/api/password/reset/";

export const Login = async (payload) => {
  try {
    const response = await axiosInstance.post(LOGIN_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const Logout = async () => {
  try {
    const response = await axiosInstance.post(LOGOUT_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const Signup = async (payload) => {
  try {
    const response = await axiosInstance.post(SIGNUP_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const IsUserValid = async () => {
  try {
    let response = axiosInstance.get(USER_VALID_CHECK_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// OTP function
export const validateOtp = async (payload) => {
  try {
    const response = await axiosInstance.post(VALIDATE_OTP_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async (payload) => {
  try {
    const response = await axiosInstance.post(RESEND_OTP_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Forget Password functions
export const requestPasswordReset = async (email) => {
  try {
    const response = await axiosInstance.post(FORGET_PASSWORD_URL, { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (payload) => {
  try {
    const response = await axiosInstance.post(VERIFY_OTP_URL, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const submitPasswordReset = async (payload) => {
  try {
    const response = await axiosInstance.post(
      SUBMIT_PASSWORD_RESET_URL,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
