import card1 from '../../assets/images/card/card2.png';
import rightArrow from '../../assets/rightarrow.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import image1 from '../../assets/images/gallery/gallery1.png';
import image2 from '../../assets/images/gallery/gallery2.png';
import image3 from '../../assets/images/gallery/gallery3card.png';
import dottcircle from '../../assets/svg/dotcircle.svg';
import circle from '../../assets/svg/circle.svg';

const GalleryCarasol = () => {
  return (
    <>
     <div className=' bg-bannerbg relative'>
     <section className="2xl:max-w-[1500px] w-[80%] mx-auto">
        <h6 className=" 2xl:text-xl max-2xl:text-lg py-3 text-primary font-medium">
          PHOTOS
        </h6>
        <h4 className="title">Our Gallery</h4>
        <p className=" sub-title">
          On MAANG careers, instructors from all over the world <br /> who work
          at MAANG companies
        </p>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper mx-auto  "
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            650: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          <SwiperSlide>
            <div className="custom-shadow mx-auto p-2 w-full max-w-[350px] mt-10   ">
              <img src={image1} alt="" className=" h-full " />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto p-2 w-full max-w-[350px] mt-10   ">
              <img src={image2} alt="" className=" h-full " />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto p-2 w-full max-w-[350px] mt-10   ">
              <img src={image3} alt="" className=" h-full " />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto p-2 w-full max-w-[350px] mt-10   ">
              <img src={image3} alt="" className=" h-full " />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="custom-shadow mx-auto p-2 w-full max-w-[350px] mt-10   ">
              <img src={image3} alt="" className=" h-full " />
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <img
        src={circle}
        alt=""
        className=" absolute top-[10%]  -left-8 max-md:hidden"
      />
      <img
        src={dottcircle}
        alt=""
        className=" absolute top-40 left-16 max-md:hidden"
      />
     </div>
    </>
  );
};

export default GalleryCarasol;
