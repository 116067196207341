import React from 'react';
import Banner from '../../components/Program/Banner';
import ProgramMain from '../../components/Program/ProgramMain';
import Service from '../../components/Program/Service';
import CertificateCarasol from '../../components/allcarasol/CertificateCarasol';
import ImageBanner from '../../components/allbanner/ImageBanner';
import ProgramCarasol from '../../components/Program/ProgramCarasol';

const Program = () => {
  return (
    <div className="">
      <div className="">
        {' '}
        <Banner />
      </div>

      <ProgramMain />
      <Service />
      <div className=" relative -top-10 pb-10">
        <ImageBanner />
      </div>

      <ProgramCarasol />
    </div>
  );
};

export default Program;
