import avtar from '../../assets/images/girl.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import linkdien from '../../assets/svg/liendien.svg';
import fullcircle from '../../assets/svg/solidround.svg';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import dottcircle from '../../assets/svg/dotcircle.svg';

const Carasol1 = () => {
  return (
    <div className=" bg-white relative">
      <section className=" 2xl:max-w-[1500px] mx-auto px-5 sm:w-[80%]">
        <h4 className="title">Placement Stories</h4>
        <h5 className="sub-title">
          Discover the alumni who walked the same path as you
        </h5>
        <div className=" mt-5">
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper carasol1 "
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              639: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
            }}
          >
          
            <SwiperSlide>
              <div className=" max-w-mw-645   mx-auto  p-4 !pt-1 mt-5 custom-shadow  text-start">
                <div className=" flex  gap-2  ">
                  <img
                    src={avtar}
                    alt=""
                    className=" h-14 bg-cpink w-14 rounded-full relative -top-5"
                  />
                  <div className=" font-sans">
                    <h4 className=" text-base font-medium text-black ">
                      Darrell Steward
                    </h4>
                    <h6 className=" 2xl:text-sm font-normal text-secondary text-[10px]">
                      Adobe | Software Engineer
                    </h6>
                  </div>
                </div>
                <p className="2xl:text-base text-secondary font-normal text-[10px] max-sm:text-sm  pr-12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et magna aliqua. ad
                  minim veniam, quis nostrud exercitation nisi ullamco laboris
                  ut aliquip ex ea commodo consequat.
                </p>
                <img
                  src={linkdien}
                  alt=""
                  className=" ml-auto relative -top-5"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" max-w-mw-645   mx-auto  p-4 !pt-1 mt-5 custom-shadow  text-start">
                <div className=" flex  gap-2  ">
                  <img
                    src={avtar}
                    alt=""
                    className=" h-14 bg-cpink w-14 rounded-full relative -top-5"
                  />
                  <div className=" font-sans">
                    <h4 className=" text-base font-medium text-black ">
                      Darrell Steward
                    </h4>
                    <h6 className=" 2xl:text-sm font-normal text-secondary text-[10px]">
                      Adobe | Software Engineer
                    </h6>
                  </div>
                </div>
                <p className="2xl:text-base text-secondary font-normal text-[10px] max-sm:text-sm  pr-12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et magna aliqua. ad
                  minim veniam, quis nostrud exercitation nisi ullamco laboris
                  ut aliquip ex ea commodo consequat.
                </p>
                <img
                  src={linkdien}
                  alt=""
                  className=" ml-auto relative -top-5"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" max-w-mw-645   mx-auto  p-4 !pt-1 mt-5 custom-shadow  text-start">
                <div className=" flex  gap-2  ">
                  <img
                    src={avtar}
                    alt=""
                    className=" h-14 bg-cpink w-14 rounded-full relative -top-5"
                  />
                  <div className=" font-sans">
                    <h4 className=" text-base font-medium text-black ">
                      Darrell Steward
                    </h4>
                    <h6 className=" 2xl:text-sm font-normal text-secondary text-[10px]">
                      Adobe | Software Engineer
                    </h6>
                  </div>
                </div>
                <p className="2xl:text-base text-secondary font-normal text-[10px] max-sm:text-sm  pr-12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et magna aliqua. ad
                  minim veniam, quis nostrud exercitation nisi ullamco laboris
                  ut aliquip ex ea commodo consequat.
                </p>
                <img
                  src={linkdien}
                  alt=""
                  className=" ml-auto relative -top-5"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className=" max-w-mw-645   mx-auto  p-4 !pt-1 mt-5 custom-shadow  text-start">
                <div className=" flex  gap-2  ">
                  <img
                    src={avtar}
                    alt=""
                    className=" h-14 bg-cpink w-14 rounded-full relative -top-5"
                  />
                  <div className=" font-sans">
                    <h4 className=" text-base font-medium text-black ">
                      Darrell Steward
                    </h4>
                    <h6 className=" 2xl:text-sm font-normal text-secondary text-[10px]">
                      Adobe | Software Engineer
                    </h6>
                  </div>
                </div>
                <p className="2xl:text-base text-secondary font-normal text-[10px] max-sm:text-sm  pr-12">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et magna aliqua. ad
                  minim veniam, quis nostrud exercitation nisi ullamco laboris
                  ut aliquip ex ea commodo consequat.
                </p>
                <img
                  src={linkdien}
                  alt=""
                  className=" ml-auto relative -top-5"
                />
              </div>
            </SwiperSlide>
           
          </Swiper>
        </div>
      </section>
      <img
        src={dottcircle}
        alt=""
        className=" absolute right-10 top-1 max-md:hidden"
      />
      <img
        src={dottcircle}
        alt=""
        className=" absolute left-24 top-24 max-md:hidden"
      />
      <img src={fullcircle} alt="" className=" absolute top-1/2 max-md:hidden  -left-8" />
    </div>
  );
};

export default Carasol1;
