import React from 'react';
import Banner from '../../components/Program/Banner';
import WorkMain from '../../components/Works/WorkMain';
import DashBoard from '../../components/Works/DashBoard';
import ImageBanner from '../../components/allbanner/ImageBanner';
import WorkBanner from '../../components/Works/WorkBanner';
import WorkImgBanner from '../../components/Works/WorkImgBanner';
import LastBanner from '../../components/Works/LastBanner';

const Work = () => {
  return (
    <div className=" ">
      <WorkBanner />

      <WorkMain />
      <div className="">
        <WorkImgBanner />
      </div>

      <DashBoard />
      <div className="  relative top-20">
        <LastBanner />
      </div>
    </div>
  );
};

export default Work;
