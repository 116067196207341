import React from 'react';
import Button from '../button/Button';
import rightarrow from '../../assets/svg/Rightsidearrow/whiteRightarrow.svg';
import dualimg from '../../assets/images/about/dualsection.png';
import circle from '../../assets/svg/circle.svg';
import dottcircle from '../../assets/svg/dotcircle.svg';

const DualSection = () => {
  return (
    <div className=" relative">
      <section className=" sub-section">
        <div className=" max-w-mw1328 flex mx-auto lg:gap-20 max-sm:gap-10 sm:gap-5 max-sm:flex-col  justify-between items-start">
          <div className="text-start flex-1">
            <h4 className=" title">Become an instructor</h4>
            <h3 className=" 2xl:text-2xl font-medium text-secondary max-lg:text-sm lg:text-base">
              let's join us with your knowledge
            </h3>
            <p className=" sub-title  !pl-0 mb-8">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore Ut enim ad minim veniam, Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore Ut enim ad minim veniam,Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore Ut enim ad minim veniam,
            </p>
            <Button
              text="Become An Instructor"
              image={rightarrow}
              bgColor="bg-primary"
              textColor="text-white"
            />
          </div>
          <div className="flex-1">
            <img src={dualimg} alt="" className="  w-full h-full   " />
          </div>
        </div>
      </section>
      <img
        src={circle}
        alt=""
        className=" absolute  -right-10 bottom-2 max-sm:hidden "
      />
      <img
        src={dottcircle}
        alt=""
        className=" absolute  right-10 -bottom-16 max-md:hidden "
      />
    </div>
  );
};

export default DualSection;
