import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import logo from "../../assets/images/Logo_White 1.svg";
import img1 from "../../assets/images/blog/blog1.png";
import downarrow from "../../assets/images/downchevron.svg";
import portal from "../../assets/svg/portal.svg";
import profile from "../../assets/svg/profile.svg";
import blacktrophy from "../../assets/svg/blacktrophy.svg";
import navmenu from "../../assets/svg/navmenu.svg";
import logout from "../../assets/svg/logout.svg";
import white from "../../assets/svg/navmenuwhite.svg";
import { Logout } from "../../services/Auth.service";
import { useUserStore } from "../../store/store";
import { useCookie } from "react-use";

const Header = () => {
  const [mobnav, setMobNav] = useState(false);
  const user = useUserStore((state) => state.user);
  const removeAllUser = useUserStore((state) => state.removeAllUser);
  const [loggedIn, , deleteLoggedIn] = useCookie("maang");
  const isLoggedIn = !!(loggedIn && Object.keys(loggedIn).length > 0);
  const [activeLink, setActiveLink] = useState("home");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const smaallNav = () => {
    setMobNav(!mobnav);
  };
  const location = useLocation();

  // const hideProfile = ["/login", "/signup"];
  // const isHideProfile = hideProfile.includes(location.pathname);

  const isHomePage = location.pathname === "/";
  const isContest3Page =
    location.pathname === "/contest3" ||
    location.pathname === "/contest2" ||
    location.pathname === "/bookcourse";

  // const navbarhide = ["/resetsuccess","/createpassword","/otpvarify","/otpsuccess"]

  const contest4 = location.pathname === "/contest4";
  const contest5 = location.pathname === "/nextquestion";

  const [dropdown, setDropDown] = useState(false);

  const DropDown = () => {
    setDropDown(!dropdown);
  };

  if (contest4) {
    return null;
  }
  if (contest5) {
    return null;
  }
  // if (isHideProfile) {
  //   return null;
  // }

  const profilePaths = ["/profile", "/program"];
  const isProfilePage = profilePaths.includes(location.pathname);

  const LogoutHandler = async (e) => {
    try {
      e.preventDefault();
      await Logout();
    } catch (err) {
      console.log(err);
    }
    deleteLoggedIn();
    removeAllUser();
    document.location.replace("/");
  };

  function getName() {
    if (user && user.user) return user.user.username.split("@")[0];
    else return "";
  }
  const resetpass = location.pathname === "/resetsuccess";
  const createpass = location.pathname === "/createpassword";
  const otpvarify = location.pathname === "/otpvarify";
  const otpsuccess = location.pathname === "/otpsuccess";

  // if (resetpass) {
  //   return null;
  // }
  // if (createpass) {
  //   return null;
  // }
  // if (otpvarify) {
  //   return null;
  // }
  // if (otpsuccess) {
  //   return null;
  // }

  return (
    <div
      className={`flex justify-between items-center top-0 left-0 right-0 z-50 p-6 main-section relative`}
    >
      <div className="h-8">
        <img src={logo} alt="" className="h-full w-full" />
      </div>
      <ul
        className={`text-xl pl-12 font-medium flex items-center gap-6 max-lg:hidden ${
          isContest3Page ? "!text-white" : "text-cblack"
        }`}
      >
        <li
         
        >
          <Link to="/" className={` ${activeLink === "home"  ?   "text-primary" : "text-black" }`} onClick={() => handleLinkClick("home")}>
            Home
            <br />
            <div  className={`text-primary ${
            activeLink === "home" ? "border-b-2 w-[20%]  border-primary" : ""
          } ${isContest3Page ? "!text-white" : ""}`}> </div>
          </Link>
        </li>
        <li
       
        >
          <Link to="/program" className={` ${activeLink === "program"  ?   "text-primary" : "text-black" }`} onClick={() => handleLinkClick("program")}>
            Program
            <br />
            <div  className={`text-primary ${
            activeLink === "program" ? "border-b-2 w-[20%]  border-primary" : ""
          } ${isContest3Page ? "!text-white" : ""}`}> </div>
          </Link>
        </li>
        <li
     
        >
          <Link to="/works" onClick={() => handleLinkClick("works")} className={` ${activeLink === "works"  ?   "text-primary" : "text-black" }`}>
            How it works
            <br />
            <div  className={`text-primary ${
            activeLink === "works" ? "border-b-2 w-[20%]  border-primary" : ""
          } ${isContest3Page ? "!text-white" : ""}`}> </div>
          </Link>
        </li>
        <li
         
        >
          <Link to="/contactus" className={` ${activeLink === "contactus"  ?   "text-primary" : "text-black" }`} onClick={() => handleLinkClick("contactus")}>
            Contact Us
            <br />
            <div  className={`text-primary ${
            activeLink === "contactus" ? "border-b-2 w-[20%]  border-primary" : ""
          } ${isContest3Page ? "!text-white" : ""}`}> </div>
          </Link>
        </li>
      </ul>
      {mobnav && (
        <ul className="2xl:text-f22 text-base  mobilenav text-center right-0   font-medium absolute top-16 mx-auto bg-primary text-white items-center gap-6 w-[200px]">
          <li className="my-2 p-2">
            <Link to="/">
              Home <br /> <div className=""></div>
            </Link>
          </li>
          <li className=" my-2 p-2">
            <Link to="/program">Program</Link>
          </li>
          <li className=" my-2 p-2">
            <Link to="/works">How it works</Link>
          </li>
          <li className=" my-2 p-2">
            <Link to="/contactus"> Contact Us</Link>
          </li>
        </ul>
      )}

      {isHomePage && (
        <div
          className={`flex items-center justify-between gap-3 max-lg:hidden `}
        >
          <button className="custom-button text-primary border-primary">
            <Link to="/login">Log in</Link>
          </button>
          <button className="custom-button bg-primary text-white border-primary">
            <Link to="/signup">Create a free account</Link>
          </button>
        </div>
      )}
      {!isHomePage && isLoggedIn && (
        <div className="mt-1 text-start flex justify-start items-center gap-2 relative max-lg:hidden">
          <h4
            className={`2xl:text-xl font-semibold max-sm:text-sm max-2xl:text-base text-cblack ${
              isContest3Page ? "!text-white" : ""
            }`}
          >
            {getName()}
          </h4>
          <button onClick={DropDown}>
            <div className="flex justify-start gap-2 items-center">
              <img
                src={img1}
                alt=""
                className=" h-10 w-10 rounded-full object-cover"
              />
              <img src={downarrow} alt="" />
            </div>

            {dropdown && (
              <ul className="bg-white absolute top-14 w-[150px] flex flex-col justify-center items-start  z-50 right-0 rounded-md py-2 px-5 custom-shadow text-cblack font-medium text-base">
                <li className="my-2 hover:text-primary text-primary flex items-center gap-2  justify-start">
                  <img src={portal} alt="" /> <span>My Portal</span>
                </li>
                <li className="my-2 hover:text-primary flex items-center gap-2  justify-start">
                  <img src={profile} alt="" />{" "}
                  <Link to="/profile">My profile</Link>
                </li>
                <li className="my-2 hover:text-primary flex items-center gap-2  justify-start">
                  <img src={blacktrophy} alt="" />{" "}
                  <Link to="/contest1">My contest</Link>
                </li>
                <li
                  onClick={LogoutHandler}
                  className="my-2 hover:text-primary flex items-center gap-2  justify-start"
                >
                  <img src={logout} alt="" />
                  Log out
                </li>
              </ul>
            )}
          </button>
        </div>
      )}

      <button onClick={smaallNav} className="hidden max-lg:block">
        {isContest3Page ? (
          <img src={white} alt="" className="hidden max-lg:block" />
        ) : (
          <img src={navmenu} alt="" className="hidden max-lg:block" />
        )}
      </button>
    </div>
  );
};

export default Header;
