import React from "react";
import rightarrow from "../../assets/svg/Rightsidearrow/rightarrowgreen.svg";
import dottedcircle from "../../assets/svg/whitdotcircle.svg";
import greendot from "../../assets/svg/dotcircle.svg";
import phone from "../../assets/svg/phone/bluephone.svg";
import CardCarasol from "../../components/course/CardCarasol";
import CertificateCarasol from "../../components/allcarasol/CertificateCarasol";
import CourseBanner from "../../components/course/CourseBanner";
import CourseMainBanner from "../../components/course/CourseMainBanner";
const Course = () => {
  return (
    <>
      <div className="  !pt-0 pb-10">
        <div className=" ">
      <CourseMainBanner/>
          <CardCarasol />
          <CertificateCarasol />
          <CourseBanner />
        </div>
      </div>
    </>
  );
};

export default Course;
